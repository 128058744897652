.contact-block {
  padding-right: rem(40);
  font-size: rem(16);

  &__title {
    margin: 0 0 rem(10);
    font-size: rem(24);
    line-height: lh(39, 32);
    font-weight: 900;
  }

  &__address {
    opacity: .72;

    p {
      margin: 0;
    }

    a {
      color: inherit;
    }
  }

  .text-link {
    display: inline-block;
    margin-top: rem(8);
  }

}

@include above($tablet) {
  .contact-block {
    &__title {
      margin: 0 0 rem(16);
      font-size: rem(32);
    }

    .text-link {
      display: inline-block;
      margin-top: rem(10);
    }
  }
}
