.cta {
  margin-top: rem(60);
  text-align: center;

  &__visual {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: rem(311);
      height: auto;
      margin-left: rem(-20);
    }
  }

  &__title,
  &__description {
    max-width: rem(580);
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem(24);
    font-weight: 900;
  }

  &__description {
    opacity: .72;
    margin-top: rem(16);
    margin-bottom: rem(32);

    p {
      margin: 0
    }
  }
}


@include above($tablet) {
  .cta {
    margin-top: rem(120);

    &__visual {
      img {
        width: rem(422);
        margin-left: rem(-30);
      }
    }

    &__title {
      font-size: rem(40);
    }
  }
}
