.article-teaser {
  width: 100%;

  & + & {
    margin-top: rem(16);
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  &__visual {
    display: none;
  }

}


.article-teaser--block {
  margin-bottom: rem(40);

  &, a,
  .article-teaser__inner {
    height: 100%;
  }

  .article-teaser {
    &__inner {
      flex-direction: column;
      justify-content: stretch;
    }

    &__visual {
      margin-top: 0;
      width: 100%;
      display: block;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__content {
      width: auto;
      padding-left: 0;
      display: flex;
      flex-direction: column;
    }
  }


  .article__title {
    margin: rem(20) 0;
    flex-grow: 1;
  }

  .article__excerpt {
    color: inherit;
    opacity: .72;
  }

}

@supports(object-fit: cover) {

  .article-teaser--block {
    .article-teaser__visual {
      height: rem(157);

      @include above($tablet) {
        height: rem(253);
      }

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

}


@include above($tablet) {
  .article-teaser {
    & + & {
      margin-top: rem(32);
    }

    &__visual {
      width: rem(160);
      margin-top: rem(8);
      display: block;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__content {
      width: calc(100% - #{rem(160)});
      // padding-left: rem(24);
    }

    &__visual + &__content {
      padding-left: rem(24);
    }
  }

  .article-teaser--block {
    .article-teaser__visual + .article-teaser__content {
      padding-left: 0;
    }
  }
}
