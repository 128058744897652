.page-jobs {

  .article-section__content {
    ul, ol {
      padding: 0;
      list-style: none;
      max-width: rem(600);
      line-height: lh(48, 24);

      li {
        position: relative;
        padding-left: rem(26);

        &:before {
          content: '+';
          font-weight: 900;
          font-size: rem(24);
          line-height: lh(48, 32);
          position: absolute;
          left: 0px;
          margin-top: -.03em;
          color: $sky-blue;
          opacity: .72;
        }
      }

      @include above($tablet) {
        li {
          &:before {
            margin-top: .03em;
          }
        }
      }

      @include above($desktop-sm) {
        ul, ol {
          font-size: rem(20);
        }
      }
    }
  }

}
