.article__body {

  a {
    color: $sky-blue;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  figure {
    img {
      width: 100%;
      height: auto;
    }
  }

  ol, ul, p, blockquote {
    // opacity: .72;
    color: rgba($text-color, .72);
  }


  p, ol, ul, blockquote {
    margin-top: rem(16);
    margin-bottom: 0;

    // &:first-child {
    //   margin-top: 0;
    // }
  }

  h3, h4, h5, h6 {
    &:first-child,
    & + p,
    & + ol,
    & + ul,
    & + pre,
    & + figure,
    & + img {
      margin-top: rem(8);
    }
  }



  ol ul, ul ol, ul ul, ol ol {
    margin-top: 0;
    margin-bottom: rem(16);
  }

  p:empty {
    display: none;
  }

  // h2, h3, h4, h5, h6 {
  //   &:first-child,
  //   & + p,
  //   & + ol,
  //   & + ul,
  //   & + pre,
  //   & + code,
  //   & + figure,
  //   & + img {
  //     margin-top: 0;
  //   }
  // }

  // p + ol, p + ul {
  //   margin-top: 0;
  // }


  h1 {
    font-size: rem(32);
    font-weight: 900;
    margin-bottom: 10px;
  }

  h2 {
    font-size: rem(28);
    font-weight: 900;
  }

  h3 {
    font-size: rem(24);
    font-weight: 900;
  }

  h4 {
    font-size: rem(20);
  }

  h5 {
    font-size: rem(16);
    font-weight: 900;
  }

  h6 {
    font-size: rem(14);
    text-transform: uppercase;
    font-weight: 900;
    color: #9EA6B3;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: rem(32);
    margin-bottom: 0;
  }
}



@include above($tablet) {
  .article__body {

    ol ul, ul ol, ul ul, ol ol {
      margin-top: 0;
      margin-bottom: rem(20);
    }

    h1, h2, h3, h4, h5, h6 {
      &:first-child {
        margin-top: rem(65);
      }
    }

  }
}
