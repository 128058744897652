.article-section--text-image {
  overflow: hidden;

  @include below($tablet){
    margin-bottom: rem(60);
  }

  .article-section__inner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
  }


  .article-section__content {
    position: relative;
    z-index: 1;
    padding-top: rem(40);

    > h2, > div {
      // max-width: rem(530);
    }

    > h2 { margin-top: 0; }
  }

  .article-section__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: rem(60);
    padding: 0;

    &.image-with-background {
      background-color: $snow-white;
    }

    img {
      margin-top: rem(-20);
      max-width: 100%;
      width: auto;
      position: relative;
      z-index: 1;
    }

  }
}


body.page-about {
  .article-section--text-image {
    background-color: $snow-white;
    padding-top: rem(40);
    position: relative;

    // &::after {
    //   content: '';
    //   display: block;
    //   height: 20%;
    //   background-image: linear-gradient(to top, $white, rgba($white, 0));
    //   position: absolute;
    //   bottom: -2px;
    //   z-index: 1;
    //   width: 100%;
    // }

    .article-section__inner {
      flex-direction: column-reverse;
    }

    .article-section__content {
      padding: 0;
      width: 100%;
    }

    .article-section__image {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      background-color: transparent;
      width: auto;
      margin-top: rem(40);

      img {
        margin-top: 0;
        width: 100%;
        height: auto;
        max-width: rem(256);
      }

    }
  }
}

@include above($tablet) {
  .article-section--text-image {
    .article-section__inner {
      flex-direction: row;
    }

    .article-section__content {
      width: 50%;
      padding-left: rem(20);
    }

    .article-section__image {
      margin-right: 0;
      margin-top: rem(30);
      width: 50%;
    }
  }

  body.page-about {
    .article-section--text-image {

      .article-section__inner {
        flex-direction: row;
      }

      .article-section__content {
        padding-left: rem(20);
        width: 60%;
      }

      .article-section__image {
        margin: 0;
        width: 40%;
      }
    }
  }
}

@include above($tablet) {

  .article-section--text-image {
    .article-section__inner {
      flex-direction: row;
      justify-content: space-between;
    }

    .article-section__content {
      width: 50%;
      padding: 0 rem(30) 0 0;

      &--right {
        padding: 0;
      }
    }

    .article-section__image {
      margin: rem(30) auto 0;
      // max-width: rem(530);
      width: calc(50% - #{rem(30)});

      &--right {
        margin-left: 0;
      }
    }
  }

  body.page-about {
    .article-section--text-image {
      padding-top: 0;
      background-color: transparent;

      .article-section__inner {
        flex-direction: row;
      }

      .article-section__content {
        padding-top: 8%;
        padding-bottom: 8%;
        width: 50%;
      }
      .article-section__image {
        width: 50%;
        margin-left: 0;
        margin-right: 0;

        img {
          // max-width: rem(350);
        }

        figure {
          position: relative;

          &:after {
            content: '';
            display: block;
            height: 20%;
            background-image: linear-gradient(to top, $white, rgba($white, 0));
            position: absolute;
            bottom: -2px;
            z-index: 1;
            width: 100%;
          }
        }

        &::before {
          content: '';
          display: block;
          background-color: $snow-white;
          width: 100vw;
          position: absolute;
          left: 50%;
          top: 2%;
          bottom: 25%;
          z-index: 0;
          min-height: 580px;
          height: 100%;
        }
      }
    }
  }
}


@include above($desktop) {
  body.page-about {
    .article-section--text-image {
      .article-section__image {
        img {
          max-width: rem(434);
        }
      }
    }
  }
}
