blockquote,
.blockquote {
  max-width: rem(720);
  text-align: center;
  margin: auto;
  padding-bottom: rem(28);


  &:after {
    content: '';
    display: block;
    width: rem(200);
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: rem(-100);
   // border-bottom: 1px solid rgba($cloud-grey, .56);
  }
  // &:after {
  //   content: '';
   //  display: block;
  //   width: rem(200);
  //   position: absolute;
  //   bottom: 0;
  //   left: 50%;
  //   margin-left: rem(-100);
  //   border-bottom: 1px solid rgba($cloud-grey, .56);
  // }

  p {
    margin: 0;
  }
}


.article-section__inner {
  blockquote,
  .blockquote {
    max-width: 100%;
    text-align: left;
    padding-bottom: 0;
    font-size: .9em;
    padding-left: 1.111em;
    border-left: 4px solid #D8D8D8;
  }
}
