.categories {
  color: $sky-blue;
  text-transform: uppercase;

  &__item {
    display: inline-block;
    font-size: rem(12);
    font-weight: 700;
    line-height: 1;

    &:after {
      content: '//';
      display: inline-block;
      margin: 0 0 0 .2em;
    }

    &, a {
      color: inherit;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

  }

}

@include above($tablet) {
  .categories {
    &__item {
      font-size: rem(16);
    }
  }
}
