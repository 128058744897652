.article {

  &__header-img {
    img {
      width: 100%;
      height: auto;
      max-height: 616px;
      object-fit: cover;
    }
  }

  &__title {
    font-size: rem(24);
    line-height: lh(39, 32);
    font-weight: 900;
    margin: 0 ;
    word-break: break-word;
  }

  .categories,
  .article__author {
    display: none;
  }

  &__author {
    font-size: rem(16);
    font-weight: 700;
    margin: 0 0 rem(8);
  }

  &__excerpt {
    color: #99a1ac;
    margin: rem(8) 0;
  }

  &__content {
    figure {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}


@include above($tablet) {
  .article {
    &__title {
      font-size: rem(32);
    }

    .categories,
    .article__author {
      display: block;
    }
  }
}
