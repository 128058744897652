.case-teaser {
  margin-top: rem(60);

  a {
    color: inherit;
    text-decoration: none;
  }

  &__inner {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    // align-items: center;
  }


  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(40);

    img {
      max-width: rem(210);
      width: 100%;
      height: auto;
      transition: transform .2s ease-in-out;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 200px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(to top, $white 15%, rgba($white, 0));
      z-index: 3;
    }
  }


  &__title {
    font-size: rem(24);
    font-weight: 900;
    margin: 0;
  }

  &__excerpt {
    margin: .3em 0;
    opacity: .72;
    font-size: rem(16)
  }

  .text-link {
    display: inline-block;
    margin-top: .6em;
    font-size: rem(16);
  }

  &:hover {
    .case-teaser__image img {
      transform: translateY(-10px);
      transform-origin: center bottom;
    }
  }
}

@include above($tablet-xs) {
  .case-teaser {
    margin-top: rem(90);

    & + & {
      margin-top: rem(180);
    }

    &__image {
      width: 40%;
      margin-top: 0;

      img {
        max-width: rem(500);
      }
    }
    &__content {
      width: 60%;
      max-width: rem(500);
      padding-left: rem(40);
    }

    &__inner {
      flex-direction: row;
      align-items: center;
    }
  }


.case-teaser:last-child {
  margin-bottom: rem(80);
}


  .case-teaser:nth-child(even) {
    .case-teaser{
      &__inner {
        flex-direction: row-reverse;
      }

      &__content {
        padding-left: 0;
        padding-right: rem(40);
      }
    }
  }
}

@include above($tablet) {
  .case-teaser {
    margin-top: rem(100);

    & + & {
      margin-top: rem(100);
    }

    // &__image,
    // &__content {
    //   width: 50%;
    // }

    &__title {
      font-size: rem(40);
    }
    &__excerpt,
    .text-link {
      font-size: rem(20);
    }
  }
}

@include above($desktop) {
  .case-teaser {
    &__image,
    &__content {
      width: 50%;
    }
  }
}
