.job-teaser {
  height: rem(311);
  background-color: $snow-white;
  position: relative;
  &__header {
    position: absolute;
    bottom: 0;
    z-index: 1;

    width: 100%;
    padding: rem(24);
  }

  &__image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: rem(-20);
      left: 0;
      right: 0;
      background-image: linear-gradient(to top, $white 15%, rgba($white, 0));
      z-index: 1;
    }

    img {
      width: auto;
      max-height: 100%;
    }
  }

  &__title {
    font-size: rem(24);
    font-weight: 900;
    color: $text-color;
    margin: 0;
  }

  &__subtitle {
    color: $text-color;
    margin: 0;
    opacity: .56;
  }
}


@include above($tablet) {
  .job-teaser {
    @include aspect-ratio(324, 497);
    height: auto;

    &__image {

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@include above($desktop-sm) {
  .job-teaser {
    &__title {
      font-size: rem(32);
    }
  }
}
