.page-header--video {
  margin-top: rem(-$header-height-small);
  //color: $white;
  position: relative;

  .page-header__bg {
    position: relative;
    z-index: 0;
    height: rem(415);

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .page-header__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: rem(120);
    z-index: 2;
  }

  .page-header__title {
    margin-top: 0;
  }
}

@include above($mobile) {
  .page-header--video {
    .page-header__inner {
      top: 30%;
    }
  }
}

@include above($tablet-xs) {
  .page-header--video {
    margin-top: rem(-$header-height-medium);

    .page-header__inner {
      top: 30%;
    }
  }
}

@include above($tablet) {
  .page-header--video {
    margin-top: rem(-$header-height-medium);

    .page-header__bg {
      height: rem(616);
      overflow: hidden;
    }
  }
}



@include above($desktop) {
  .page-header--video {
    margin-top: rem(-$header-height-large);
  }
}


.inverted .page-header--video {
  color: $white;
}