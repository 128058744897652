.contact-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(60);

  &__item {
    width: rem(240);
    margin-bottom: rem(40);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
