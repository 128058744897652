.page-header {
  margin-top: rem(74);

  &__title {
    font-size: rem(40);
    line-height: 1;
    font-family: $ff-secondary;
    font-weight: 700;
    margin: 0;
  }

  &__bg {
    max-width: rem($desktop-hd);
    //max-width: none;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__bg-video {
    max-width: 100%;
  }
}


@include above($tablet) {
  .page-header {
    margin-top: rem(120);

    &__title {
      $fs: 64;
      font-size: rem($fs);
      line-height: lh(81, $fs);
    }
  }
}



@include above($desktop) {
  .page-header {
    margin-top: rem(110);

    &__title {
      $fs: 72;
      font-size: rem($fs);
      line-height: lh(91, $fs);
    }
  }
}
