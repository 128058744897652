.lang-switch {
  display: flex;

  &__label,
  &__link {
    opacity: .72;
  }

  &__label {
    margin-right: rem(24)
  }

  &__link {
    color: inherit;
    text-decoration: none;

    &.active {
      opacity: 1;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  &__link + &__link {
    margin-left: rem(16)
  }
}
