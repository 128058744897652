.jobs-grid {
  &__item {
    margin-bottom: rem(20);
    width: 100%;
    padding: 0 5px;
  }
}

@include above($tablet-xs) {
  .jobs-grid {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;

    &__item {
      @include portrait() {
        width: 50%;
      }
    }
  }
}

@include above($tablet-md) {
  .jobs-grid {
    &__item {
      @include portrait() {
        width: 33.333%;
      }
    }
  }

}


@include between($tablet + 1, $desktop-hd) {
  .jobs-grid {
    max-width: rem(710);
    margin-left: auto;
    margin-right: auto;

    &__item {
      width: 50%;
    }
  }
}


@include above($desktop-hd) {
  .jobs-grid {
    &__item {
      width: 33.333%;
    }
  }
}
