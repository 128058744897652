$screen: if(variable-exists(screen), $screen, 'screen');

// Base Mixin
@mixin bp($min, $mq: min-width, $type: $screen) {
  @if $type != '' and $type != null{
    $type: $type + ' and';
  }

  $query: '#{$type} (#{$mq}: #{$min})';

  @if type-of($min) == string {
    $query: $min;
  }
  @media #{$query} {
    @content;
  }
}
