.article-section {
  margin: rem(30) 0 0 0;

  @include below($tablet){
    margin: rem(25) 0;
  }

  &:first-child {
    margin-top: rem(10);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.article-section--single-img {
    //margin: rem(16);
  }
}


.page-blogs {
  .article-section {
    //margin: rem(40) 0;

    &:first-child {
      margin-top: rem(10);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}


@include above($tablet) {
  .article-section {
    //margin: rem(70) 0;

    &:first-child {
      margin-top: rem(40);
    }
  }

  .page-blogs {
    .article-section {
      &:first-child {
        margin-top: rem(40);
      }
    }
  }
}


@include above($desktop) {
  .article-section {
    //margin: rem(120) 0;

    &:first-child {
      margin-top: rem(40);
    }
  }

  .page-blogs {
    .article-section {
      &:first-child {
        margin-top: rem(40);
      }
    }
  }
}
