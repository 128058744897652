// code {

// }
.article-section__inner pre {
  background-color: #F4F6FB;
  color: #7D8795;
  font-style: italic;
  // font-size: .9em;
  white-space: pre-wrap;
  line-height: 1.6;
  padding: 13px;
  border-radius: 3px;
}

.article-section__inner pre.hljs-parent {
  font-style: normal;
  padding: 0;
  background-color: transparent;

  code {
    padding: 13px;
  }
}

.article-section__inner > code,
blockquote code,
p code {
  background-color: #FCE4DB;
  color: $dutch-orange;
  font-family: $ff-primary;
  font-size: inherit;
  line-height: 1.6;
  padding: 0 .2em;
  border-radius: 3px;
  // display: inline-block;
}

// pre code:not(.hljs) {
//   font-family: $ff-mono;
// }
