.site-footer {
  background-color: $snow-white;
  margin-top: rem(110);

  &__inner.constrict {
    max-width: rem(1220);
    position: relative;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: rem(40);

    .footer-widget {
      width: calc(50% - #{rem(20)});
      margin-top: rem(40);

      &:nth-child(1) {
        margin-top: 0;
        width: 100%;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column-reverse;
    // align-items: center;
  }
}



@include above($tablet-md) {
  .site-footer {

    &__top {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: rem(95);

      .footer-widget {
        width: rem(180);
        margin-left: rem(40);
        margin-top: 0;

        &:nth-child(1) {
          margin-left: 0;
          width: rem(180);
          flex-grow: 1;
          margin-right: rem(40);
        }
      }
    }

    &__bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}


@include above($desktop) {
  .site-footer {
    margin-top: rem(180);
  }
}


@include between($tablet-md, $desktop) {
  @media (orientation: portrait) {
    .site-footer {
      margin-top: rem(90);
    }
  }

  // @media (orientation: landscape) {
  //   .site-footer {
  //     margin-top: 0;
  //   }
  // }
 }
