figure {
  margin: 0;

  img {
    display: block;
  }

  figcaption {
    font-size: rem(16);
    font-style: italic;
    margin-top: rem(10);
    opacity: .72;
  }
}
