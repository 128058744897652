.service {
  text-align: center;

  &__title {
    font-size: rem(24);
    font-weight: 900;
    margin: 0 0 .5em 0;
  }

  &__description {
    p {
      line-height: lh(32, 20);
      margin: 0;
    }
  }
}


.services-grid__item {
  &:nth-child(1) {
    .service__title {
      color: $navy-blue;
    }
  }

  &:nth-child(2) {
    .service__title {
      color: $sky-blue;
    }
  }

  &:nth-child(3) {
    .service__title {
      color: $dutch-orange;
    }
  }
}


@include above($tablet) {
  .service {
    text-align: center;

    &__title {
      font-size: rem(40);
    }

  }
}
