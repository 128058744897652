// ==========================================================================
// Layout
// Layout rules divide the page into sections. Layouts hold one or more
// modules together.
// ==========================================================================

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  z-index: 0;
  flex-grow: 1;
  padding-top: rem($header-height-small);

  @include above($tablet-xs) {
    padding-top: rem($header-height-medium);
  }

  @include above($desktop) {
    padding-top: rem($header-height-large);
  }
}

.constrict {
  max-width: rem($desktop-hd);
  margin-left: auto;
  margin-right: auto;
  padding-left: rem(20);
  padding-right: rem(20);

  &--smaller {
    max-width: rem($desktop);
  }

  &--smallest {
    max-width: rem($desktop-sm);
  }

  &--tiny {
    max-width: rem(790);
  }
}


@include above($tablet) {
  .constrict {
    padding-left: rem(40);
    padding-right: rem(40);
  }
}
