.article-section--passion {
  overflow: hidden;

  .article-section__inner {
    display: flex;
    overflow: hidden;
  }

  .article-section__image,
  .article-section__content {
    width: 50%;
  }

  .article-section__content {
    padding-left: rem(20);
    position: relative;
    z-index: 1;
    padding-top: 8%;

    > h2, > div {
      max-width: rem(530);
    }
  }

  .article-section__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      display: block;
      background-color: $snow-white;
      width: 100vw;
      position: absolute;
      left: 50%;
      top: 2%;
      bottom: 25%;
      z-index: 0;
    };

    &::after {
      content: '';
      display: block;
      height: 20%;
      background-image: linear-gradient(to top, $white, rgba($white, 0));
      position: absolute;
      bottom: -2px;
      z-index: 1;
      width: 100%;
    }

    img {
      position: relative;
      z-index: 1;
      max-width: rem(434);
      // margin-left: rem(-60);
    }

  }
}


body.page-about {

}
