.large-header {
  background-color: $snow-white;
  margin-top: rem(-$header-height-small);

  padding-top: rem($header-height-small);
  padding-bottom: rem($header-height-small);
  height: rem(415);
  position: relative;
  margin-bottom: rem(80);
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-left: auto;
  // margin-right: auto;
  // max-width: rem($full-width-max);


  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to top, $white 15%, rgba($white, 0));
    z-index: 3;
  }

  &__title {
    margin: rem(20) 0 0;
    padding: 0 rem(20);
    font-size: rem(40);
    line-height: 1;
    font-weight: 900;
    z-index: 1;
    text-align: center;
    position: relative;
    opacity: 0;
  }

  &__visual {
    position: absolute;
    width: rem(314);
    overflow: hidden;
    bottom: 0;
    z-index: 2;

  }

  &__visual,
  &__visual-fg,
  &__visual-bg {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: inline-block;
      width: auto;
      max-width: 90%;
      height: auto;
      max-height: 80%;
      opacity: 0;

      //
    }
  }

  // &__visual-bg {
  //   z-index: 0;
  //   position: relative;
  // }

  // &__visual-fg {
  //   z-index: 2;
  //   bottom: rem($header-height-small);
  //   left: 50%;
  //   transform: translateX(-50%)
  // }

  &__visual-fg,
  &__visual-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: rem(320);
  }

  &__visual-bg {
    z-index: 0;
  }

  &__visual-fg {
    z-index: 2;
  }
}


@include above($desktop-sm) {
  .large-header {
    margin-top: rem(-$header-height-medium);
    padding-top: rem($header-height-medium);
    padding-bottom: rem($header-height-medium);
    padding-left: rem(40);
    padding-right: rem(40);
    height: rem(615);

    &__title {
      font-size: rem(100)
    }

    &__visual {
      width: 100%;
      max-width: rem($desktop-hd);
    }
  }
}


@include above($desktop) {
  .large-header {
    margin-top: rem(-$header-height-large);
    padding-top: rem($header-height-large);
    padding-bottom: rem($header-height-large);
    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      margin: 0;
      font-size: rem(120);
    }

    &__visual-fg,
    &__visual-bg {
      position: absolute;
      transform: translateX(0);
      bottom: 0;
      left: auto;
      max-width: rem($desktop-hd);
    }
  }
}


body.page-jobs .large-header {
  &__visual {
    overflow: hidden;

    img {
      margin-bottom: rem(-30);
    }

  }
}


body.page-cases .large-header {
  height: auto;

  @include above($desktop) {
    height: rem(615);
  }
}


@include below($desktop-sm) {
  body.page-cases-single {



    .large-header__title {
      margin: 0;
    }

    .large-header__visual,
    .large-header__visual-bg,
    .large-header {
      overflow: hidden;
      display: block;
      position: relative;
      left: auto;
      transform: none;
    }
  }
}
