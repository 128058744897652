#map {
  height: rem(310);  /* The height is 400 pixels */
  width: 100%;  /* The width is the width of the web page */
  margin: rem(40) 0;

  @include above($tablet) {
    height: rem(410);
    margin: rem(120) 0 rem(148);
  }
}
