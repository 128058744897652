/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
      outline: none;
  }

  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
      content: "";
      display: table;
  }

  &:after {
      clear: both;
  }

  .slick-loading & {
      visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
      float: right;
  }
  img {
      display: block;
  }
  &.slick-loading img {
      display: none;
  }

  display: none;

  &.dragging img {
      pointer-events: none;
  }

  .slick-initialized & {
      display: block;
  }

  .slick-loading & {
      visibility: hidden;
  }

  .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}


.slick-slide {
  .product-slide__visual img {
    opacity: 0;
    transform: translate(-100px, -50px) rotate(10deg) scale(.9);
    transform-origin: top right;
    transition: all .4s cubic-bezier(0.175, 0.885, 0.320, 1.275);
  }

  .product-slide__content > * {
    opacity: 0;
    transform: translateY(50px);

    &:nth-child(1) { transition-delay: 0s; }
    &:nth-child(2) { transition-delay: 0s; }
    &:nth-child(3) { transition-delay: 0s; }
    &:nth-child(4) { transition-delay: 0s; }
  }


  &.slick-current {
    .product-slide__content > *,
    .product-slide__visual img {
      transition: all .4s cubic-bezier(0.175, 0.885, 0.320, 1.275);
    }
  }
}



.slick-slide.revealSlide {
  .product-slide__visual img {
    opacity: 1;
    transform: translate(0, 0) rotate(0deg) scale(1);
  }

  .product-slide__content > * {
    opacity: 1;
    transform: translateY(0);

    &:nth-child(1) { transition-delay: .2s; }
    &:nth-child(2) { transition-delay: .15s; }
    &:nth-child(3) { transition-delay: .1s; }
    &:nth-child(4) { transition-delay: .05s; }
  }
}


.slick-slide.hideSlide {
  .product-slide__visual img {
    opacity: 0;
    transform: translate(100px, 0) rotate(-5deg) scale(1);
    transform-origin: top right;
  }

  .product-slide__content > * {
    opacity: 0;
    transform: translateY(50px);;
    transition: all .4s cubic-bezier(0.175, 0.885, 0.320, 1.275);

    &:nth-child(1) { transition-delay: .2s; }
    &:nth-child(2) { transition-delay: .15s; }
    &:nth-child(3) { transition-delay: .1s; }
    &:nth-child(4) { transition-delay: .05s; }
  }
}


.slick-arrow {
  width: rem(28);
  height: rem(49);
  position: relative;
  overflow: hidden;
  background-color: transparent;
  background-position: center;
  background-size: rem(28) rem(49);
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  top: 50%;
  margin-top: rem(-25);
  outline: none;
  cursor: pointer;
  transition: opacity .3s ease-in-out;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
  }

  &.slick-prev {
    background-image: url('../img/arrow-left.svg');
    left: rem(-50);
  }

  &.slick-next {
    background-image: url('../img/arrow-right.svg');
    right: rem(-50);
  }

  &.slick-disabled {
    opacity: .16;
  }
}

.article__body ul.slick-dots,
ul.slick-dots {
  list-style: none;
  padding: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: rem(16);

  li {
    @include size(rem(20));
    opacity: .24;
    transition: opacity .3s ease-in-out;

    &.slick-active {
      opacity: 1;
    }

    button {
      @include size(100%);
      padding: 0;
      border: none;
      overflow: hidden;
      position: relative;
      background-color: transparent;
      outline: none;
      cursor: pointer;

      &:after {
        @include circle(rem(6));
        content: '';
        position: absolute;
        background-color: $black;
        top: 50%;
        left: 50%;
        margin-top: rem(-3);
        margin-left: rem(-3);
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
  }
}
