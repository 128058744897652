.site-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;

  &__inner {
    height: rem($header-height-small);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }
}


@include above($tablet-xs) {
  .site-header {
    &__inner {
      height: rem($header-height-medium);
    }
  }
}


@include above($tablet) {
  .site-header {
    &__inner {
      height: rem($header-height-large);
    }
  }
}
