.section {

  &--cases {
    margin: rem(80) 0 0;

    @include above($tablet) {
      margin: rem(160) 0 0;
    }
  }

  &--clients {
    margin: rem(40) 0 rem(40);

    @include above($tablet) {
      margin: rem(84) 0 rem(164);
    }
  }

  &--services {
    $c: $snow-white;
    background-image: linear-gradient(to bottom, $c, rgba($c, 0));
    padding: rem(40) 0 rem(80);

    @include above($tablet) {
      padding: rem(50) 0 rem(120);
    }
  }

  &--articles {
    padding: rem(40) 0 0;

    @include above($tablet) {
      padding: rem(120) 0 0;
    }
  }
}

.inverted {
  .section {
    &--services {
      background: transparent !important;
    }
  }
}
