.services-grid {
  &__item + &__item {
    margin-top: rem(40);
  }
}

@include above($desktop-sm) {
  .services-grid {
    display: flex;
    justify-content: space-between;

    &__item {
      width: calc(33.333% - 20px);
    }

    &__item + &__item {
      margin-top: 0;
    }
  }
}
