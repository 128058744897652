.lottie-wrapper {
  // display: none !important;

  width: 100%;
  position: absolute;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);
}

.lottie-wrapper__inner {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: auto;
  overflow: hidden;
}

#lottie2,
#lottie {
  width: rem(2133);
  height: auto;
  display: block;
  transform: translate3d(0,0,0);
  transform-origin: center center;
  text-align: center;
  opacity: 1;
  position: relative;
  position: absolute;

  @include below($tablet + 1) {
    transform: translate3d(0,0,0) scale(.8);
  }

  @include below($mobile) {
    transform: translate3d(0,0,0) scale(.5);
  }
}



// HOMEPAGE
body[data-lottie='3'],
.page-home {
  .lottie-wrapper__inner {
    top: rem(20);
    left: rem(15);
    width: calc(100% - #{rem(15)});
    max-width: rem(424);
    height: rem(400);
  }

  #lottie {
    top: rem(-610);
    left: rem(-1090);

    @include below($tablet + 1) {
      top: rem(-620);
      left: rem(-1100);
    }

    @include below($mobile) {
      top: rem(-605);
      left: rem(-1085);
    }
  }
}

body[data-lottie='6'],
.site-footer {
  position: relative;

  .lottie-wrapper {
    top: rem(-500);
    z-index: -1;
  }

  .lottie-wrapper__inner {
    top: rem(20);
    left: auto;
    right: rem(15);
    max-width: rem(480);
    width: rem(480);
    height: rem(480);
  }

  #lottie2 {
    top: rem(-125);
    left: rem(-700);
    transform: translate3d(0,0,0) scale(1.17);

    @include below($tablet + 1 + 1) {
      transform: translate3d(0,0,0) scale(.8);

      top: rem(-125);
      left: rem(-670);
    }

    @include below($mobile) {
      transform: translate3d(0,0,0) scale(.5);

      top: rem(-125);
      left: rem(-700);
    }
  }
}


// BLOG
body[data-lottie='2'],
.page-blog {
  .lottie-wrapper__inner {
    top: rem(0);
    right: rem(15);
    width: calc(100% - #{rem(15)});
    max-width: rem(1140);
    height: rem(490);
  }

  #lottie {
    top: rem(-530);
    left: rem(-480);

    @include below($tablet + 1) {
      top: rem(-537);
      left: rem(-560);
    }

    @include below($mobile) {
      top: rem(-520);
      left: rem(-885);
    }
  }
}


// CONTACT
body[data-lottie='5'],
.page-contact {
  .lottie-wrapper__inner {
    top: rem(70);
    right: rem(15);
    width: calc(100% - #{rem(15)});
    max-width: rem(480);
    height: rem(430);

    @include below($tablet + 1) {
      top: rem(60);
    }
  }

  #lottie {
    top: rem(-400);
    left: rem(-560);

    @include below($tablet + 1) {
      top: rem(-450);
      left: rem(-570);
    }

    @include below($mobile) {
      top: rem(-540);
      left: rem(-655);
    }
  }
}



// ABOUT
body[data-lottie='1'],
.page-about {
  .lottie-wrapper,
  .lottie-wrapper__inner {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
  }

  .lottie-wrapper__inner {
    svg > g > g > g {
      opacity: .16;

      &#image_55,
      &#image_47,
      &#image_39,
      &#image_27 {
        opacity: 1;

        @include below($tablet + 1) {
          opacity: .16;
        }
      }
    }
  }

  #lottie {
    top: rem(-200);
    right: rem(-600);

    @include below($tablet + 1) {
      top: rem(-300);
      right: rem(-720);
    }

    @include below($mobile) {
      top: rem(-400);
      right: auto;
      left: rem(-900);
    }
  }
}



// CASES
body[data-lottie='4'],
.page-cases {
  .lottie-wrapper__inner {
    top: rem(70);
    right: rem(15);
    width: calc(100% - #{rem(15)});
    max-width: rem(760);
    height: rem(420);

    @include below($tablet + 1) {
      top: rem(60);
    }

    @include below($mobile) {
      top: rem(50);
    }
  }

  #lottie {
    top: rem(-660);
    left: rem(-470);
    // background-color: rgba(red, .2);

    @include below($tablet + 1) {
      top: rem(-650);
      left: rem(-425);
    }

    @include below($mobile) {
      top: rem(-630);
      left: rem(-785);
    }
  }
}

body[data-lottie=''],
.page-cases-single {
  .lottie-wrapper {
    top: 0;
  }

  .lottie-wrapper__inner {
    top: rem(130);
    left: rem(15);
    width: calc(100% - #{rem(15)});
    max-width: rem(760);
    height: rem(470);

    @include below($tablet + 1) {
      top: rem(60);
      height: rem(350);
    }

  }

  #lottie {
    top: rem(-450);
    left: rem(-1090);

    @include below($tablet + 1) {
      top: rem(-500);
      left: rem(-1090);
    }

    @include below($mobile) {
      top: rem(-540);
      left: rem(-1085);
    }
  }
}



// JOBS
body[data-lottie='8'],
.page-jobs {
  .lottie-wrapper__inner {
    top: 0;
    right: rem(15);
    width: calc(100% - #{rem(15)});
    max-width: rem(1300);
    height: rem(440);
  }

  #lottie {
    top: rem(-600);
    right: rem(-715);

    @include below($tablet + 1) {
      right: rem(-800); // 86;
    }

    @include below($mobile) {
      top: rem(-590); // 20;
      right: rem(-890); // 170;
    }
  }
}

//.page-jobs-single
body[data-lottie='7']{
  .lottie-wrapper,
  .lottie-wrapper__inner {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    z-index: 0;
  }

  #lottie {
    top: 50%;
    left: 50%;
    transform: translate3d(0,0,0) translate(-50%, -50%);

    @include below($tablet + 1) {
      transform-origin: center center;
      transform: translate3d(0,0,0) translate(-50%, -50%) scale(.8);
    }

    @include below($mobile) {
      transform: translate3d(0,0,0) translate(-50%, -50%) scale(.5);
    }
  }
}

.large-header .lottie-wrapper {
  top: 0;
}

.page-blogs-single {
  .lottie-wrapper {
    z-index: 1;
  }
}
