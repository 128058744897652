.share-nav {
  margin: rem(20) 0;

  &__list {
    display: flex;
  }

  &__item {
    opacity: .32;
    transition: opacity .3s ease-in-out;

    &:hover {
      opacity: .72;
    }

    &,
    a {
      color: $text-color;
      display: block;
      width: rem(32);
      height: rem(32);
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg path {
      fill: currentColor;
    }
  }

  &__item + &__item {
    margin-left: rem(12);
  }

}

@include above($tablet + 1) {
  .share-nav {
    margin: rem(8) 0 0 0;

    &__list {
      display: block;
    }

    &__item + &__item {
      margin-left: 0;
      margin-top: rem(12);
    }
  }
}
