.services-title {
  font-size: rem(24);
  font-weight: 900;
  margin: 0 0 rem(30);
  text-align: center;
}



@include above($tablet) {
  .services-title {
    font-size: rem(54);
    margin: 0 0 rem(64);
  }

}
