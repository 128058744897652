.page-subtitle {
  font-size: rem(24);
  font-weight: 900;
  margin-top: rem(110);
  margin-bottom: 1em;
  padding-bottom: .5em;
  border-bottom: 1px solid rgba($text-color, .56);
}

@include above($tablet) {
  .page-subtitle {
    font-size: rem(40);
    margin-top: rem(190);
  }
}
