#video-section {
  position: relative;
  overflow: hidden;
}

.video-container {
  position: relative;
  z-index: 0;
  overflow: hidden;
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // max-width: rem($desktop-sm);
  // height: rem(470);
  margin: auto;
  will-change: width, max-width;
}

.video {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  object-fit: cover;
  object-position: center;
  margin: auto;
  z-index: 0;
  overflow: hidden;
  border-radius: .1px; // chrome overflow bugfix
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;


  // @include above($tablet) {
  //   max-width: rem(1060);
  //   height: auto;
  // }
}


.video-text {
  position: absolute;
  opacity: 0;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__inner {
    width: 100%;
    padding-top: 80vh;

    // @include above($tablet) {
    //   padding-top: rem(600);
    // }
  }

  &__title {
    font-size: rem(40);
    margin: 0;
  }

  &__content {
    opacity: .72;
    max-width: rem(710);
    margin-top: .5em;

    a {
      display: inline-block;
      color: inherit;
      font-weight: 700;
      margin-top: .5em;
    }
  }
}


.video-frame {
  position: absolute;
  z-index: 2;
  background-color: $white;
}

#video-frame-top,
#video-frame-bottom {
  width: 100%;
  height: rem(40);
  right: 0;

  @include above($tablet) {
    height: rem(80);
  }
}

#video-frame-left,
#video-frame-right {
  width: rem(40);
  height: 100%;

  @include above($tablet) {
    width: rem(80);
  }
}

#video-frame-top {
  top: 0;
}

#video-frame-bottom {
  bottom: 0;
}


#video-frame-left {
  left: 0;
}

#video-frame-right {
  right: 0;
}
