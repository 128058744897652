.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: rem(444);
  height: rem(52);
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(99, 90, 255, 0.16);
  background-color: $sky-blue;
  color: $white;
  text-decoration: none;
  font-weight: 700;
  transition: all .3s ease-in-out;

  &:hover {
    background-color: darken($sky-blue, 5);
    transform: translateY(-2px);
  }
}
