@media screen and (max-width: 1441px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1440px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1439px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1251px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1250px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1249px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1141px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1140px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1139px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1025px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1024px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1023px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 769px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 768px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 767px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 721px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 720px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 719px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 601px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 600px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 599px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 481px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 480px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 479px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (min-width: 479px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 480px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 599px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 600px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 719px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 720px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 767px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 768px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1023px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1024px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1139px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1140px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1249px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1250px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1439px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1440px) {
  .foo {
    bar: 'above'; } }

html {
  box-sizing: border-box;
  line-height: 1.15; }

*,
*::before,
*::after {
  box-sizing: inherit; }

:root {
  -moz-tab-size: 4;
  tab-size: 4; }

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  color: #414f64;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  line-height: 1.5; }
  @media screen and (min-width: 1024px) {
    body {
      font-size: 1.25rem;
      line-height: 1.6; } }

hr {
  height: 0; }

abbr[title] {
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp,
pre {
  font-family: Courier, monospace;
  font-size: 0.875rem; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  padding: 0; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

summary {
  display: list-item; }

blockquote {
  margin-left: 0; }

figure {
  margin: 0; }
  figure img {
    display: block; }
  figure figcaption {
    font-size: 1rem;
    font-style: italic;
    margin-top: 0.625rem;
    opacity: .72; }

nav ul {
  padding: 0;
  margin: 0;
  list-style: none; }

h1, h2, h3, h4, h5, h6, p, ul, ol, dl, * {
  word-break: break-word; }

blockquote,
.blockquote,
.slider-caption {
  max-width: 45rem;
  text-align: center;
  margin: auto;
  padding-bottom: 1.75rem; }
  blockquote:after,
  .blockquote:after,
  .slider-caption:after {
    content: '';
    display: block;
    width: 12.5rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -6.25rem; }
  blockquote p,
  .blockquote p,
  .slider-caption p {
    margin: 0; }

.article-section__inner blockquote,
.article-section__inner .blockquote,
.article-section__inner .slider-caption {
  max-width: 100%;
  text-align: left;
  padding-bottom: 0;
  font-size: .9em;
  padding-left: 1.111em;
  border-left: 4px solid #D8D8D8; }

.blog-title {
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 900;
  margin: 0 0 1.875rem; }

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 27.75rem;
  height: 3.25rem;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(99, 90, 255, 0.16);
  background-color: #009bee;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  transition: all .3s ease-in-out; }
  .btn:hover {
    background-color: #008ad5;
    transform: translateY(-2px); }

.categories {
  color: #009bee;
  text-transform: uppercase; }
  .categories__item {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1; }
    .categories__item:after {
      content: '//';
      display: inline-block;
      margin: 0 0 0 .2em; }
    .categories__item, .categories__item a {
      color: inherit; }
    .categories__item:last-child:after {
      display: none; }

@media screen and (min-width: 768px) {
  .categories__item {
    font-size: 1rem; } }

.clients-title {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 900;
  margin: 0 0 1.25em;
  text-align: center; }

@media screen and (min-width: 768px) {
  .clients-title {
    font-size: 2.5rem; } }

.article-section__inner pre {
  background-color: #F4F6FB;
  color: #7D8795;
  font-style: italic;
  white-space: pre-wrap;
  line-height: 1.6;
  padding: 13px;
  border-radius: 3px; }

.article-section__inner pre.hljs-parent {
  font-style: normal;
  padding: 0;
  background-color: transparent; }
  .article-section__inner pre.hljs-parent code {
    padding: 13px; }

.article-section__inner > code,
blockquote code,
p code {
  background-color: #FCE4DB;
  color: #ef5a22;
  font-family: "Lato", sans-serif;
  font-size: inherit;
  line-height: 1.6;
  padding: 0 .2em;
  border-radius: 3px; }

.checkbox {
  display: inline-block; }
  .checkbox__input {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0); }
  .checkbox__label::before {
    content: ''; }

.radio {
  display: inline-block; }
  .radio__input {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0); }
  .radio__label::before {
    content: ''; }

.legal-text {
  margin: 0;
  padding: 1em 0;
  opacity: .72;
  font-size: 1rem; }

.logo {
  display: block; }
  .logo svg {
    display: block;
    width: 100%;
    height: auto; }
    .logo svg path {
      fill: #000000; }
    .logo svg path.logo-name {
      display: none; }

.site-header .logo {
  width: 2rem;
  height: 2rem;
  overflow: hidden; }
  .site-header .logo svg {
    display: block;
    width: auto;
    height: 100%; }

.site-footer .logo {
  width: 4.5rem;
  height: 4.5rem;
  overflow: hidden;
  transform: translateY(-50%); }
  .site-footer .logo svg {
    display: block;
    width: auto;
    height: 100%; }

@media screen and (min-width: 600px) {
  .logo {
    display: block; }
    .logo svg path.logo-name {
      display: block; }
  .site-header .logo {
    width: auto;
    height: 2.5rem; } }

@media screen and (min-width: 768px) {
  .site-header .logo {
    height: 2.75rem; } }

.marked {
  position: relative; }
  .marked u {
    background-color: rgba(0, 155, 238, 0.32);
    position: absolute;
    height: 0.5625rem;
    bottom: .25em;
    left: -.1em;
    right: calc(100% + .1em);
    z-index: -1;
    animation-name: UNDERLINE;
    animation-duration: .25s;
    animation-delay: .25s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards; }

@keyframes UNDERLINE {
  0% {
    right: 100%; }
  100% {
    right: 0%; } }

@media screen and (min-width: 600px) {
  .marked u {
    height: 0.75rem; } }

@media screen and (min-width: 768px) {
  .marked u {
    height: 1rem; } }

.menu-icn {
  width: 2rem;
  position: relative;
  display: block;
  height: 0.1875rem; }
  .menu-icn__inner, .menu-icn::before, .menu-icn::after {
    display: block;
    width: 100%;
    height: 0.1875rem;
    top: 0;
    background-color: #000;
    border-radius: 2px;
    position: absolute; }
  .menu-icn__inner {
    transform: scale(1, 1);
    transform-origin: top left;
    transition-property: transform, background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0.4s, 0.3s; }
  .menu-icn::before, .menu-icn::after {
    content: '';
    transition-property: transform, top, background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s, 0.2s, 0.3s; }
  .menu-icn::before {
    top: -0.5625rem; }
  .menu-icn::after {
    top: 0.5625rem; }
  .show-menu .menu-icn__inner {
    transform-origin: top right;
    transform: scale(0, 1);
    transition-delay: 0s, 0s, 0s; }
  .show-menu .menu-icn::before, .show-menu .menu-icn::after {
    top: 0;
    transition-delay: 0.4s, 0.2s, 0s; }
  .show-menu .menu-icn::before {
    transform: rotate(-45deg); }
  .show-menu .menu-icn::after {
    transform: rotate(45deg); }

.main-nav__item a {
  position: relative;
  padding-left: 0.75rem;
  z-index: 0; }
  .main-nav__item a .dot {
    width: 0.5625rem;
    height: 0.5625rem;
    display: inline-block;
    background-color: #ef5a22;
    position: absolute;
    bottom: .25em;
    left: 0;
    transition: width .25s ease-in-out;
    z-index: -1; }
  .main-nav__item a:hover .dot {
    width: 100%; }

@media screen and (min-width: 768px) {
  .main-nav__item a {
    padding-left: 1.0625rem; }
    .main-nav__item a .dot {
      width: 0.6875rem;
      height: 0.6875rem; } }

.main-nav__item a.active .dot {
  width: 100%; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: -999;
  opacity: 0;
  transition-property: z-index, opacity;
  transition-duration: 0s, .3s;
  transition-delay: .8s, .3s; }
  @supports (-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px)) {
    .overlay {
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px); } }
  .show-menu .overlay {
    z-index: 98;
    opacity: 1;
    transition-delay: 0s, .1s; }

@supports not ((-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px))) {
  .main {
    transition: filter .3s ease-in-out; }
  .show-menu .main {
    filter: blur(8px); }
  @-moz-document url-prefix() {
    .show-menu .main {
      filter: none; } } }

.page-subtitle {
  font-size: 1.5rem;
  font-weight: 900;
  margin-top: 6.875rem;
  margin-bottom: 1em;
  padding-bottom: .5em;
  border-bottom: 1px solid rgba(65, 79, 100, 0.56); }

@media screen and (min-width: 768px) {
  .page-subtitle {
    font-size: 2.5rem;
    margin-top: 11.875rem; } }

.services-title {
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0 0 1.875rem;
  text-align: center; }

@media screen and (min-width: 768px) {
  .services-title {
    font-size: 3.375rem;
    margin: 0 0 4rem; } }

.text-link {
  color: #009bee;
  font-weight: 700;
  text-decoration: underline; }

.toggle-menu {
  border: none;
  padding: 0;
  background-color: transparent;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer; }

.article-teaser {
  width: 100%; }
  .article-teaser + .article-teaser {
    margin-top: 1rem; }
  .article-teaser__inner {
    display: flex;
    flex-wrap: wrap; }
  .article-teaser a {
    text-decoration: none;
    color: inherit; }
  .article-teaser__visual {
    display: none; }

.article-teaser--block {
  margin-bottom: 2.5rem; }
  .article-teaser--block, .article-teaser--block a,
  .article-teaser--block .article-teaser__inner {
    height: 100%; }
  .article-teaser--block .article-teaser__inner {
    flex-direction: column;
    justify-content: stretch; }
  .article-teaser--block .article-teaser__visual {
    margin-top: 0;
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden; }
    .article-teaser--block .article-teaser__visual img {
      width: 100%;
      height: auto; }
  .article-teaser--block .article-teaser__content {
    width: auto;
    padding-left: 0;
    display: flex;
    flex-direction: column; }
  .article-teaser--block .article__title {
    margin: 1.25rem 0;
    flex-grow: 1; }
  .article-teaser--block .article__excerpt {
    color: inherit;
    opacity: .72; }

@supports (object-fit: cover) {
  .article-teaser--block .article-teaser__visual {
    height: 9.8125rem; }
    @media screen and (min-width: 768px) {
      .article-teaser--block .article-teaser__visual {
        height: 15.8125rem; } }
    .article-teaser--block .article-teaser__visual img {
      height: 100%;
      object-fit: cover;
      object-position: center; } }

@media screen and (min-width: 768px) {
  .article-teaser + .article-teaser {
    margin-top: 2rem; }
  .article-teaser__visual {
    width: 10rem;
    margin-top: 0.5rem;
    display: block; }
    .article-teaser__visual img {
      width: 100%;
      height: auto; }
  .article-teaser__content {
    width: calc(100% - 10rem); }
  .article-teaser__visual + .article-teaser__content {
    padding-left: 1.5rem; }
  .article-teaser--block .article-teaser__visual + .article-teaser__content {
    padding-left: 0; } }

.case-teaser-block {
  margin: 1.25rem 0 2.5rem;
  position: relative;
  background-color: #f4f6fb; }
  .case-teaser-block a {
    text-decoration: none; }
  .case-teaser-block__inner {
    display: flex;
    flex-direction: column-reverse; }
    .case-teaser-block__inner:before {
      content: '';
      display: block;
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(to top, #fff 15%, rgba(255, 255, 255, 0));
      z-index: 1; }
  .case-teaser-block__header {
    position: relative;
    z-index: 1;
    margin-top: -0.625rem;
    margin-bottom: 0.625rem;
    width: 100%;
    padding: 1.5rem;
    padding-top: 0; }
  .case-teaser-block__visual {
    position: relative;
    top: -1.25rem;
    bottom: 1.25rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0; }
    .case-teaser-block__visual img {
      max-width: 13.125rem;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 0;
      transition: transform .2s ease-in-out; }
  .case-teaser-block__title {
    font-size: 1.5rem;
    font-weight: 900;
    color: #414f64;
    margin: 0;
    line-height: 1.1; }
  .case-teaser-block__subtitle {
    color: #414f64;
    margin: 0;
    opacity: .72; }
  .case-teaser-block:hover .case-teaser-block__visual img {
    transform: translateY(-10px);
    transform-origin: center bottom; }

.page-home .case-teaser-block__subtitle {
  display: none; }

@media screen and (min-width: 768px) {
  .case-teaser-block {
    position: relative;
    width: calc(50% - 1.25rem);
    height: auto; }
    .case-teaser-block:before {
      display: block;
      padding-top: 100%;
      width: 100%;
      content: ' '; }
    .case-teaser-block > .case-teaser-block__inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .case-teaser-block__header {
      position: absolute;
      bottom: 0;
      margin-top: 0;
      margin-bottom: 0; }
    .case-teaser-block__visual {
      position: absolute; }
      .case-teaser-block__visual img {
        width: 80%;
        max-width: 100%; }
    .case-teaser-block__title {
      font-size: 2.5rem; }
    .case-teaser-block__subtitle {
      margin: 0.625rem 0 0; }
  .case-teaser-block:first-child {
    width: 100%; }
    .case-teaser-block:first-child::before {
      padding-top: 52.22458%; }
    .case-teaser-block:first-child .case-teaser-block__visual {
      top: -20%;
      align-items: flex-end; }
      .case-teaser-block:first-child .case-teaser-block__visual:before {
        height: 60%; }
      .case-teaser-block:first-child .case-teaser-block__visual img {
        max-height: 100%;
        height: 100%;
        max-width: 100%;
        width: auto; }
    .case-teaser-block:first-child .case-teaser-block__title {
      font-size: 3.375rem; } }

.case-teaser {
  margin-top: 3.75rem; }
  .case-teaser a {
    color: inherit;
    text-decoration: none; }
  .case-teaser__inner {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between; }
  .case-teaser__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem; }
    .case-teaser__image img {
      max-width: 13.125rem;
      width: 100%;
      height: auto;
      transition: transform .2s ease-in-out; }
    .case-teaser__image:before {
      content: '';
      display: block;
      width: 100%;
      height: 200px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(to top, #fff 15%, rgba(255, 255, 255, 0));
      z-index: 3; }
  .case-teaser__title {
    font-size: 1.5rem;
    font-weight: 900;
    margin: 0; }
  .case-teaser__excerpt {
    margin: .3em 0;
    opacity: .72;
    font-size: 1rem; }
  .case-teaser .text-link {
    display: inline-block;
    margin-top: .6em;
    font-size: 1rem; }
  .case-teaser:hover .case-teaser__image img {
    transform: translateY(-10px);
    transform-origin: center bottom; }

@media screen and (min-width: 600px) {
  .case-teaser {
    margin-top: 5.625rem; }
    .case-teaser + .case-teaser {
      margin-top: 11.25rem; }
    .case-teaser__image {
      width: 40%;
      margin-top: 0; }
      .case-teaser__image img {
        max-width: 31.25rem; }
    .case-teaser__content {
      width: 60%;
      max-width: 31.25rem;
      padding-left: 2.5rem; }
    .case-teaser__inner {
      flex-direction: row;
      align-items: center; }
  .case-teaser:last-child {
    margin-bottom: 5rem; }
  .case-teaser:nth-child(even) .case-teaser__inner {
    flex-direction: row-reverse; }
  .case-teaser:nth-child(even) .case-teaser__content {
    padding-left: 0;
    padding-right: 2.5rem; } }

@media screen and (min-width: 768px) {
  .case-teaser {
    margin-top: 6.25rem; }
    .case-teaser + .case-teaser {
      margin-top: 6.25rem; }
    .case-teaser__title {
      font-size: 2.5rem; }
    .case-teaser__excerpt,
    .case-teaser .text-link {
      font-size: 1.25rem; } }

@media screen and (min-width: 1140px) {
  .case-teaser__image, .case-teaser__content {
    width: 50%; } }

.footer-widget a {
  color: inherit; }

.footer-widget__text, .footer-widget__nav {
  opacity: .72;
  margin: 0; }

.footer-widget__text p, .footer-widget__nav p {
  margin: 0; }

.footer-widget__title {
  margin: .5em 0;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.21875; }

@media screen and (min-width: 768px) {
  .footer-widget__title {
    font-size: 2rem; } }

.job-teaser {
  height: 19.4375rem;
  background-color: #f4f6fb;
  position: relative; }
  .job-teaser__header {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: 1.5rem; }
  .job-teaser__image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end; }
    .job-teaser__image:before {
      content: '';
      display: block;
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: -1.25rem;
      left: 0;
      right: 0;
      background-image: linear-gradient(to top, #fff 15%, rgba(255, 255, 255, 0));
      z-index: 1; }
    .job-teaser__image img {
      width: auto;
      max-height: 100%; }
  .job-teaser__title {
    font-size: 1.5rem;
    font-weight: 900;
    color: #414f64;
    margin: 0; }
  .job-teaser__subtitle {
    color: #414f64;
    margin: 0;
    opacity: .56; }

@media screen and (min-width: 768px) {
  .job-teaser {
    position: relative;
    height: auto; }
    .job-teaser:before {
      display: block;
      padding-top: 153.39506%;
      width: 100%;
      content: ' '; }
    .job-teaser > .job-teaser__inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .job-teaser__image img {
      width: 100%;
      height: auto; } }

@media screen and (min-width: 1024px) {
  .job-teaser__title {
    font-size: 2rem; } }

.lang-switch {
  display: flex; }
  .lang-switch__label, .lang-switch__link {
    opacity: .72; }
  .lang-switch__label {
    margin-right: 1.5rem; }
  .lang-switch__link {
    color: inherit;
    text-decoration: none; }
    .lang-switch__link.active {
      opacity: 1;
      font-weight: 700;
      text-decoration: underline; }
  .lang-switch__link + .lang-switch__link {
    margin-left: 1rem; }

.main-nav {
  transition: transform .25s ease-in-out;
  transition-delay: .25s;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  top: 0;
  background-color: #fff;
  padding-top: 5.625rem;
  transform: translateY(-100%);
  display: none; }
  .main-nav__list {
    text-align: left;
    padding: 1.875rem 2.5rem 5rem;
    position: relative;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    transition-delay: 0s; }
  .main-nav__item {
    font-size: 2.25rem;
    line-height: 1.11111;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    position: relative; }
    .main-nav__item a {
      text-decoration: none;
      color: inherit; }
  .main-nav__item + .main-nav__item {
    margin-top: 1.5rem; }

.show-menu .main-nav {
  transform: translateY(0);
  transition-delay: 0s; }
  .show-menu .main-nav__list {
    opacity: 1;
    transition-delay: .25s; }

@media screen and (min-width: 768px) {
  .main-nav {
    padding-top: 4.75rem; }
    .main-nav__list {
      padding: 1.875rem 2.5rem 3.5rem;
      text-align: center; }
    .main-nav__item {
      font-size: 3.375rem; }
    .main-nav__item + .main-nav__item {
      margin-top: 2.25rem; } }

@media screen and (min-width: 480px) and (max-width: 1023px) and (orientation: landscape) {
  .main-nav__list {
    text-align: left;
    columns: 2;
    padding: 0 2.5rem 3.5rem;
    margin-top: 1.5rem; }
  .main-nav__item {
    font-size: 2.25rem; }
  .main-nav__item + .main-nav__item {
    margin-top: 1.5rem; } }

.page-header {
  margin-top: 4.625rem; }
  .page-header__title {
    font-size: 2.5rem;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    margin: 0; }
  .page-header__bg {
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto; }
    .page-header__bg img {
      width: 100%;
      height: auto; }
  .page-header__bg-video {
    max-width: 100%; }

@media screen and (min-width: 768px) {
  .page-header {
    margin-top: 7.5rem; }
    .page-header__title {
      font-size: 4rem;
      line-height: 1.26562; } }

@media screen and (min-width: 1140px) {
  .page-header {
    margin-top: 6.875rem; }
    .page-header__title {
      font-size: 4.5rem;
      line-height: 1.26389; } }

.page-header--image {
  margin-top: -3.75rem;
  position: relative; }
  .page-header--image .page-header__bg {
    position: relative;
    z-index: 0;
    height: 25.9375rem; }
    .page-header--image .page-header__bg img {
      width: 100%;
      height: 100%;
      max-height: 616px;
      object-fit: cover;
      object-position: center; }
  .page-header--image .page-header__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 7.5rem;
    z-index: 2; }
  .page-header--image .page-header__title {
    margin-top: 0; }

@media screen and (min-width: 480px) {
  .page-header--image .page-header__bg {
    height: auto; }
  .page-header--image .page-header__inner {
    top: 30%; } }

@media screen and (min-width: 600px) {
  .page-header--image {
    margin-top: -4.5rem; }
    .page-header--image .page-header__inner {
      top: 30%; } }

@media screen and (min-width: 768px) {
  .page-header--image {
    margin-top: -4.5rem; } }

@media screen and (min-width: 1140px) {
  .page-header--image {
    margin-top: -4.75rem; } }

.inverted .page-header--image {
  color: #fff; }

.page-header--video {
  margin-top: -3.75rem;
  position: relative; }
  .page-header--video .page-header__bg {
    position: relative;
    z-index: 0;
    height: 25.9375rem; }
    .page-header--video .page-header__bg video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .page-header--video .page-header__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 7.5rem;
    z-index: 2; }
  .page-header--video .page-header__title {
    margin-top: 0; }

@media screen and (min-width: 480px) {
  .page-header--video .page-header__inner {
    top: 30%; } }

@media screen and (min-width: 600px) {
  .page-header--video {
    margin-top: -4.5rem; }
    .page-header--video .page-header__inner {
      top: 30%; } }

@media screen and (min-width: 768px) {
  .page-header--video {
    margin-top: -4.5rem; }
    .page-header--video .page-header__bg {
      height: 38.5rem;
      overflow: hidden; } }

@media screen and (min-width: 1140px) {
  .page-header--video {
    margin-top: -4.75rem; } }

.inverted .page-header--video {
  color: #fff; }

.related-case {
  width: 100%;
  margin: 2.5rem 0; }
  .related-case a {
    color: inherit;
    text-decoration: none; }
  .related-case__inner {
    display: flex;
    align-items: stretch;
    height: 100%; }
  .related-case__label {
    display: none; }
  .related-case__item {
    width: 100%;
    background-color: #f4f6fb;
    padding: 1.625rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .related-case__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.875rem; }
    .related-case__image img {
      max-width: 17.5rem;
      width: 80%;
      height: auto; }
  .related-case__title {
    font-size: 2rem;
    margin: 0;
    line-height: 1.1;
    font-weight: 900; }

.related-case--next .related-case__inner {
  flex-direction: row-reverse; }

.related-case--next .related-case__label__inner {
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left; }

.related-case--prev .related-case__label__inner {
  transform: rotate(90deg) translateY(-100%);
  transform-origin: top left; }

@media screen and (min-width: 720px) {
  .related-cases {
    max-width: 45rem;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem; }
    .related-cases.constrict {
      padding: 0; }
  .related-case {
    width: 21.25rem;
    height: 25.875rem; }
    .related-case__label {
      width: 3.75rem;
      height: 100%;
      position: relative;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1rem;
      display: block; }
    .related-case__label__inner {
      height: 3.75rem;
      width: 25.875rem;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center; }
    .related-case__item {
      width: 17.5rem; }
    .related-case__image {
      height: 17.5rem;
      margin-top: 0; } }

@media screen and (min-width: 1024px) {
  .related-cases {
    max-width: 52.5rem;
    margin-top: 5.625rem; }
  .related-case {
    width: 25rem;
    height: 31.25rem; }
    .related-case__item {
      width: 21.25rem; }
    .related-case__label {
      font-size: 1.25rem; }
    .related-case__label__inner {
      width: 31.25rem; }
    .related-case__image {
      height: 21.25rem; }
    .related-case__title {
      font-size: 2.5rem; } }

.service {
  text-align: center; }
  .service__title {
    font-size: 1.5rem;
    font-weight: 900;
    margin: 0 0 .5em 0; }
  .service__description p {
    line-height: 1.6;
    margin: 0; }

.services-grid__item:nth-child(1) .service__title {
  color: #19277d; }

.services-grid__item:nth-child(2) .service__title {
  color: #009bee; }

.services-grid__item:nth-child(3) .service__title {
  color: #ef5a22; }

@media screen and (min-width: 768px) {
  .service {
    text-align: center; }
    .service__title {
      font-size: 2.5rem; } }

.share-nav {
  margin: 1.25rem 0; }
  .share-nav__list {
    display: flex; }
  .share-nav__item {
    opacity: .32;
    transition: opacity .3s ease-in-out; }
    .share-nav__item:hover {
      opacity: .72; }
    .share-nav__item,
    .share-nav__item a {
      color: #414f64;
      display: block;
      width: 2rem;
      height: 2rem; }
    .share-nav__item a {
      display: flex;
      justify-content: center;
      align-items: center; }
    .share-nav__item svg path {
      fill: currentColor; }
  .share-nav__item + .share-nav__item {
    margin-left: 0.75rem; }

@media screen and (min-width: 769px) {
  .share-nav {
    margin: 0.5rem 0 0 0; }
    .share-nav__list {
      display: block; }
    .share-nav__item + .share-nav__item {
      margin-left: 0;
      margin-top: 0.75rem; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-slide .product-slide__visual img {
  opacity: 0;
  transform: translate(-100px, -50px) rotate(10deg) scale(0.9);
  transform-origin: top right;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.slick-slide .product-slide__content > * {
  opacity: 0;
  transform: translateY(50px); }
  .slick-slide .product-slide__content > *:nth-child(1) {
    transition-delay: 0s; }
  .slick-slide .product-slide__content > *:nth-child(2) {
    transition-delay: 0s; }
  .slick-slide .product-slide__content > *:nth-child(3) {
    transition-delay: 0s; }
  .slick-slide .product-slide__content > *:nth-child(4) {
    transition-delay: 0s; }

.slick-slide.slick-current .product-slide__content > *,
.slick-slide.slick-current .product-slide__visual img {
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.slick-slide.revealSlide .product-slide__visual img {
  opacity: 1;
  transform: translate(0, 0) rotate(0deg) scale(1); }

.slick-slide.revealSlide .product-slide__content > * {
  opacity: 1;
  transform: translateY(0); }
  .slick-slide.revealSlide .product-slide__content > *:nth-child(1) {
    transition-delay: .2s; }
  .slick-slide.revealSlide .product-slide__content > *:nth-child(2) {
    transition-delay: .15s; }
  .slick-slide.revealSlide .product-slide__content > *:nth-child(3) {
    transition-delay: .1s; }
  .slick-slide.revealSlide .product-slide__content > *:nth-child(4) {
    transition-delay: .05s; }

.slick-slide.hideSlide .product-slide__visual img {
  opacity: 0;
  transform: translate(100px, 0) rotate(-5deg) scale(1);
  transform-origin: top right; }

.slick-slide.hideSlide .product-slide__content > * {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slick-slide.hideSlide .product-slide__content > *:nth-child(1) {
    transition-delay: .2s; }
  .slick-slide.hideSlide .product-slide__content > *:nth-child(2) {
    transition-delay: .15s; }
  .slick-slide.hideSlide .product-slide__content > *:nth-child(3) {
    transition-delay: .1s; }
  .slick-slide.hideSlide .product-slide__content > *:nth-child(4) {
    transition-delay: .05s; }

.slick-arrow {
  width: 1.75rem;
  height: 3.0625rem;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  background-position: center;
  background-size: 1.75rem 3.0625rem;
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  top: 50%;
  margin-top: -1.5625rem;
  outline: none;
  cursor: pointer;
  transition: opacity .3s ease-in-out; }
  .slick-arrow:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%; }
  .slick-arrow.slick-prev {
    background-image: url("../img/arrow-left.svg");
    left: -3.125rem; }
  .slick-arrow.slick-next {
    background-image: url("../img/arrow-right.svg");
    right: -3.125rem; }
  .slick-arrow.slick-disabled {
    opacity: .16; }

.article__body ul.slick-dots,
ul.slick-dots {
  list-style: none;
  padding: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 1rem; }
  .article__body ul.slick-dots li,
  ul.slick-dots li {
    width: 1.25rem;
    height: 1.25rem;
    opacity: .24;
    transition: opacity .3s ease-in-out; }
    .article__body ul.slick-dots li.slick-active,
    ul.slick-dots li.slick-active {
      opacity: 1; }
    .article__body ul.slick-dots li button,
    ul.slick-dots li button {
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;
      overflow: hidden;
      position: relative;
      background-color: transparent;
      outline: none;
      cursor: pointer; }
      .article__body ul.slick-dots li button:after,
      ul.slick-dots li button:after {
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 50%;
        content: '';
        position: absolute;
        background-color: #000;
        top: 50%;
        left: 50%;
        margin-top: -0.1875rem;
        margin-left: -0.1875rem; }
      .article__body ul.slick-dots li button:before,
      ul.slick-dots li button:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden; }

.slider-caption {
  margin-top: 2.5rem; }
  .slider-caption:after {
    content: none; }

.slider {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.article-section .article-section__inner .slide__image img,
.slide__image img {
  display: block;
  width: 100%;
  height: auto; }

@media screen and (min-width: 768px) {
  .slider {
    margin-left: -2.5rem;
    margin-right: -2.5rem; } }

@media screen and (min-width: 1024px) {
  .slider {
    margin-left: 3.125rem;
    margin-right: 3.125rem; } }

#video-section {
  position: relative;
  overflow: hidden; }

.video-container {
  position: relative;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin: auto;
  will-change: width, max-width; }

.video {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  object-fit: cover;
  object-position: center;
  margin: auto;
  z-index: 0;
  overflow: hidden;
  border-radius: .1px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform; }

.video-text {
  position: absolute;
  opacity: 0;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .video-text__inner {
    width: 100%;
    padding-top: 80vh; }
  .video-text__title {
    font-size: 2.5rem;
    margin: 0; }
  .video-text__content {
    opacity: .72;
    max-width: 44.375rem;
    margin-top: .5em; }
    .video-text__content a {
      display: inline-block;
      color: inherit;
      font-weight: 700;
      margin-top: .5em; }

.video-frame {
  position: absolute;
  z-index: 2;
  background-color: #fff; }

#video-frame-top,
#video-frame-bottom {
  width: 100%;
  height: 2.5rem;
  right: 0; }
  @media screen and (min-width: 768px) {
    #video-frame-top,
    #video-frame-bottom {
      height: 5rem; } }

#video-frame-left,
#video-frame-right {
  width: 2.5rem;
  height: 100%; }
  @media screen and (min-width: 768px) {
    #video-frame-left,
    #video-frame-right {
      width: 5rem; } }

#video-frame-top {
  top: 0; }

#video-frame-bottom {
  bottom: 0; }

#video-frame-left {
  left: 0; }

#video-frame-right {
  right: 0; }

.article__header-img {
  margin-left: auto;
  margin-right: auto;
  margin-top: -3.75rem; }

.article__inner {
  margin-top: 1.25rem; }

.article.has-sidebar .article__body .constrict {
  padding: 0; }

@media screen and (min-width: 600px) {
  .article__header-img {
    margin-top: -4.5rem; } }

@media screen and (min-width: 769px) {
  .article__inner {
    margin-top: 5rem;
    padding-left: 7.625rem;
    padding-right: 7.625rem;
    position: relative; }
  .article__header-img {
    margin-top: -4.75rem; }
  .article__sidebar {
    position: absolute;
    left: 2.5rem; }
  .article__sidebar + .article__content {
    max-width: 44.5rem; }
  .article.has-sidebar .article__inner {
    display: flex; } }

.article-section .article-section__inner img {
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto; }

.article-section .article-section__inner img.alignnone {
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 2.5rem auto;
  display: block; }

.article-section .article-section__inner img.aligncenter {
  width: 100%;
  height: auto;
  display: block;
  margin: 2.5rem auto; }

.article-section .article-section__inner img.size-medium {
  width: 100% !important; }

.article-section .article-section__inner a img {
  width: auto;
  max-width: 100%; }

@media screen and (min-width: 1141px) {
  .article__inner {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
    position: relative; } }

@media screen and (min-width: 769px) {
  .article-section .article-section__inner img.size-medium {
    width: 50% !important;
    display: block; } }

.article-section {
  margin: 1.875rem 0 0 0; }
  @media screen and (max-width: 768px) {
    .article-section {
      margin: 1.5625rem 0; } }
  .article-section:first-child {
    margin-top: 0.625rem; }
  .article-section:last-child {
    margin-bottom: 0; }

.page-blogs .article-section:first-child {
  margin-top: 0.625rem; }

.page-blogs .article-section:last-child {
  margin-bottom: 0; }

@media screen and (min-width: 768px) {
  .article-section:first-child {
    margin-top: 2.5rem; }
  .page-blogs .article-section:first-child {
    margin-top: 2.5rem; } }

@media screen and (min-width: 1140px) {
  .article-section:first-child {
    margin-top: 2.5rem; }
  .page-blogs .article-section:first-child {
    margin-top: 2.5rem; } }

.article-section--benefits .article-section__inner {
  display: flex;
  flex-direction: column-reverse;
  max-width: 103.125rem;
  margin-left: auto;
  margin-right: auto; }

.article-section--benefits .article-section__image {
  margin-top: 2.5rem; }

.article-section--benefits .article-section__content {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  .article-section--benefits .article-section__content h2 {
    margin-top: 0; }

.article-section--benefits ul, .article-section--benefits ol {
  padding: 0;
  list-style: none;
  max-width: 37.5rem;
  line-height: 2; }
  .article-section--benefits ul li, .article-section--benefits ol li {
    position: relative;
    padding-left: 1.625rem; }
    .article-section--benefits ul li:before, .article-section--benefits ol li:before {
      content: '+';
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 1.5;
      position: absolute;
      left: 0px;
      margin-top: -.03em;
      color: #009bee;
      opacity: .72; }

@media screen and (min-width: 600px) {
  .article-section--benefits .article-section__inner {
    flex-direction: row; }
  .article-section--benefits .article-section__image,
  .article-section--benefits .article-section__content {
    width: 50%; }
  .article-section--benefits .article-section__image {
    padding-right: 1.25rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 768px) {
  .article-section--benefits .article-section__content {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .article-section--benefits .article-section__image {
    padding-right: 1.25rem;
    margin-top: 0; }
  .article-section--benefits ul li:before, .article-section--benefits ol li:before {
    margin-top: .03em; } }

@media screen and (min-width: 1024px) {
  .article-section--benefits ul, .article-section--benefits ol {
    font-size: 1.25rem; } }

@media screen and (min-width: 1140px) {
  .article-section--benefits .article-section__content {
    padding-left: 2.8125rem;
    padding-right: 1.875rem; }
  .article-section--benefits .article-section__image {
    padding-right: 2.8125rem; }
  .article-section--benefits ul, .article-section--benefits ol {
    font-size: 1.5rem;
    line-height: 2; }
    .article-section--benefits ul li, .article-section--benefits ol li {
      padding-left: 2.1875rem; }
      .article-section--benefits ul li:before, .article-section--benefits ol li:before {
        font-size: 2rem; } }

.article-section.article-section--double-text .article-section__inner {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .article-section.article-section--double-text .article-section__inner {
      flex-wrap: wrap; } }
  @media screen and (max-width: 768px) {
    .article-section.article-section--double-text .article-section__inner.article-doubletext-reverse .article-section__content:first-child {
      order: 2; } }
  .article-section.article-section--double-text .article-section__inner .article-section__content {
    width: calc(50% - 10px); }
    .article-section.article-section--double-text .article-section__inner .article-section__content:first-child {
      padding-right: 10px; }
    .article-section.article-section--double-text .article-section__inner .article-section__content h1, .article-section.article-section--double-text .article-section__inner .article-section__content h2 {
      margin-top: 1rem; }
    @media screen and (max-width: 768px) {
      .article-section.article-section--double-text .article-section__inner .article-section__content {
        width: 100%;
        padding: 0; } }

@media screen and (min-width: 768px) {
  .article-section--intro .article-section__inner {
    display: flex; }
  .article-section--intro h2 {
    width: 30%;
    padding-right: 1.25em;
    margin: 0;
    max-width: 22.5rem;
    margin-left: auto; }
  .article-section--intro .article-section__content {
    width: 70%;
    padding-left: 1.25rem; }
    .article-section--intro .article-section__content p {
      margin-top: 0; }
  .article__body .article-section--intro h2 {
    margin: 0;
    margin-left: auto; } }

@media screen and (min-width: 1024px) {
  .article-section--intro h2,
  .article-section--intro .article-section__content {
    width: 50%; } }

.article-section--passion {
  overflow: hidden; }
  .article-section--passion .article-section__inner {
    display: flex;
    overflow: hidden; }
  .article-section--passion .article-section__image,
  .article-section--passion .article-section__content {
    width: 50%; }
  .article-section--passion .article-section__content {
    padding-left: 1.25rem;
    position: relative;
    z-index: 1;
    padding-top: 8%; }
    .article-section--passion .article-section__content > h2, .article-section--passion .article-section__content > div {
      max-width: 33.125rem; }
  .article-section--passion .article-section__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
    .article-section--passion .article-section__image::before {
      content: '';
      display: block;
      background-color: #f4f6fb;
      width: 100vw;
      position: absolute;
      left: 50%;
      top: 2%;
      bottom: 25%;
      z-index: 0; }
    .article-section--passion .article-section__image::after {
      content: '';
      display: block;
      height: 20%;
      background-image: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
      position: absolute;
      bottom: -2px;
      z-index: 1;
      width: 100%; }
    .article-section--passion .article-section__image img {
      position: relative;
      z-index: 1;
      max-width: 27.125rem; }

.article-section--single-img .article-section__inner {
  width: 100%;
  max-width: 103.125rem;
  margin-left: auto;
  margin-right: auto; }

.article-section--text-image {
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .article-section--text-image {
      margin-bottom: 3.75rem; } }
  .article-section--text-image .article-section__inner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center; }
  .article-section--text-image .article-section__content {
    position: relative;
    z-index: 1;
    padding-top: 2.5rem; }
    .article-section--text-image .article-section__content > h2 {
      margin-top: 0; }
  .article-section--text-image .article-section__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3.75rem;
    padding: 0; }
    .article-section--text-image .article-section__image.image-with-background {
      background-color: #f4f6fb; }
    .article-section--text-image .article-section__image img {
      margin-top: -1.25rem;
      max-width: 100%;
      width: auto;
      position: relative;
      z-index: 1; }

body.page-about .article-section--text-image {
  background-color: #f4f6fb;
  padding-top: 2.5rem;
  position: relative; }
  body.page-about .article-section--text-image .article-section__inner {
    flex-direction: column-reverse; }
  body.page-about .article-section--text-image .article-section__content {
    padding: 0;
    width: 100%; }
  body.page-about .article-section--text-image .article-section__image {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    background-color: transparent;
    width: auto;
    margin-top: 2.5rem; }
    body.page-about .article-section--text-image .article-section__image img {
      margin-top: 0;
      width: 100%;
      height: auto;
      max-width: 16rem; }

@media screen and (min-width: 768px) {
  .article-section--text-image .article-section__inner {
    flex-direction: row; }
  .article-section--text-image .article-section__content {
    width: 50%;
    padding-left: 1.25rem; }
  .article-section--text-image .article-section__image {
    margin-right: 0;
    margin-top: 1.875rem;
    width: 50%; }
  body.page-about .article-section--text-image .article-section__inner {
    flex-direction: row; }
  body.page-about .article-section--text-image .article-section__content {
    padding-left: 1.25rem;
    width: 60%; }
  body.page-about .article-section--text-image .article-section__image {
    margin: 0;
    width: 40%; } }

@media screen and (min-width: 768px) {
  .article-section--text-image .article-section__inner {
    flex-direction: row;
    justify-content: space-between; }
  .article-section--text-image .article-section__content {
    width: 50%;
    padding: 0 1.875rem 0 0; }
    .article-section--text-image .article-section__content--right {
      padding: 0; }
  .article-section--text-image .article-section__image {
    margin: 1.875rem auto 0;
    width: calc(50% - 1.875rem); }
    .article-section--text-image .article-section__image--right {
      margin-left: 0; }
  body.page-about .article-section--text-image {
    padding-top: 0;
    background-color: transparent; }
    body.page-about .article-section--text-image .article-section__inner {
      flex-direction: row; }
    body.page-about .article-section--text-image .article-section__content {
      padding-top: 8%;
      padding-bottom: 8%;
      width: 50%; }
    body.page-about .article-section--text-image .article-section__image {
      width: 50%;
      margin-left: 0;
      margin-right: 0; }
      body.page-about .article-section--text-image .article-section__image figure {
        position: relative; }
        body.page-about .article-section--text-image .article-section__image figure:after {
          content: '';
          display: block;
          height: 20%;
          background-image: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
          position: absolute;
          bottom: -2px;
          z-index: 1;
          width: 100%; }
      body.page-about .article-section--text-image .article-section__image::before {
        content: '';
        display: block;
        background-color: #f4f6fb;
        width: 100vw;
        position: absolute;
        left: 50%;
        top: 2%;
        bottom: 25%;
        z-index: 0;
        min-height: 580px;
        height: 100%; } }

@media screen and (min-width: 1140px) {
  body.page-about .article-section--text-image .article-section__image img {
    max-width: 27.125rem; } }

.article-section--text.article-section--text-center {
  text-align: center; }

.article__header-img img {
  width: 100%;
  height: auto;
  max-height: 616px;
  object-fit: cover; }

.article__title {
  font-size: 1.5rem;
  line-height: 1.21875;
  font-weight: 900;
  margin: 0;
  word-break: break-word; }

.article .categories,
.article .article__author {
  display: none; }

.article__author {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 0.5rem; }

.article__excerpt {
  color: #99a1ac;
  margin: 0.5rem 0; }

.article__content figure img {
  width: 100%;
  height: auto; }

@media screen and (min-width: 768px) {
  .article__title {
    font-size: 2rem; }
  .article .categories,
  .article .article__author {
    display: block; } }

.article__body a {
  color: #009bee;
  font-weight: 700;
  text-decoration: none; }
  .article__body a:hover {
    text-decoration: underline; }

.article__body figure img {
  width: 100%;
  height: auto; }

.article__body ol, .article__body ul, .article__body p, .article__body blockquote {
  color: rgba(65, 79, 100, 0.72); }

.article__body p, .article__body ol, .article__body ul, .article__body blockquote {
  margin-top: 1rem;
  margin-bottom: 0; }

.article__body h3:first-child,
.article__body h3 + p,
.article__body h3 + ol,
.article__body h3 + ul,
.article__body h3 + pre,
.article__body h3 + figure,
.article__body h3 + img, .article__body h4:first-child,
.article__body h4 + p,
.article__body h4 + ol,
.article__body h4 + ul,
.article__body h4 + pre,
.article__body h4 + figure,
.article__body h4 + img, .article__body h5:first-child,
.article__body h5 + p,
.article__body h5 + ol,
.article__body h5 + ul,
.article__body h5 + pre,
.article__body h5 + figure,
.article__body h5 + img, .article__body h6:first-child,
.article__body h6 + p,
.article__body h6 + ol,
.article__body h6 + ul,
.article__body h6 + pre,
.article__body h6 + figure,
.article__body h6 + img {
  margin-top: 0.5rem; }

.article__body ol ul, .article__body ul ol, .article__body ul ul, .article__body ol ol {
  margin-top: 0;
  margin-bottom: 1rem; }

.article__body p:empty {
  display: none; }

.article__body h1 {
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 10px; }

.article__body h2 {
  font-size: 1.75rem;
  font-weight: 900; }

.article__body h3 {
  font-size: 1.5rem;
  font-weight: 900; }

.article__body h4 {
  font-size: 1.25rem; }

.article__body h5 {
  font-size: 1rem;
  font-weight: 900; }

.article__body h6 {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 900;
  color: #9EA6B3; }

.article__body h1, .article__body h2, .article__body h3, .article__body h4, .article__body h5, .article__body h6 {
  margin-top: 2rem;
  margin-bottom: 0; }

@media screen and (min-width: 768px) {
  .article__body ol ul, .article__body ul ol, .article__body ul ul, .article__body ol ol {
    margin-top: 0;
    margin-bottom: 1.25rem; }
  .article__body h1:first-child, .article__body h2:first-child, .article__body h3:first-child, .article__body h4:first-child, .article__body h5:first-child, .article__body h6:first-child {
    margin-top: 4.0625rem; } }

.contact-block {
  padding-right: 2.5rem;
  font-size: 1rem; }
  .contact-block__title {
    margin: 0 0 0.625rem;
    font-size: 1.5rem;
    line-height: 1.21875;
    font-weight: 900; }
  .contact-block__address {
    opacity: .72; }
    .contact-block__address p {
      margin: 0; }
    .contact-block__address a {
      color: inherit; }
  .contact-block .text-link {
    display: inline-block;
    margin-top: 0.5rem; }

@media screen and (min-width: 768px) {
  .contact-block__title {
    margin: 0 0 1rem;
    font-size: 2rem; }
  .contact-block .text-link {
    display: inline-block;
    margin-top: 0.625rem; } }

.contact {
  margin-top: 4rem; }

@media screen and (min-width: 768px) {
  .contact {
    margin-top: 4rem; } }

.cta {
  margin-top: 3.75rem;
  text-align: center; }
  .cta__visual {
    display: flex;
    justify-content: center;
    align-items: center; }
    .cta__visual img {
      width: 19.4375rem;
      height: auto;
      margin-left: -1.25rem; }
  .cta__title, .cta__description {
    max-width: 36.25rem;
    margin-left: auto;
    margin-right: auto; }
  .cta__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 900; }
  .cta__description {
    opacity: .72;
    margin-top: 1rem;
    margin-bottom: 2rem; }
    .cta__description p {
      margin: 0; }

@media screen and (min-width: 768px) {
  .cta {
    margin-top: 7.5rem; }
    .cta__visual img {
      width: 26.375rem;
      margin-left: -1.875rem; }
    .cta__title {
      font-size: 2.5rem; } }

.large-header {
  background-color: #f4f6fb;
  margin-top: -3.75rem;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  height: 25.9375rem;
  position: relative;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .large-header:before {
    content: '';
    display: block;
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to top, #fff 15%, rgba(255, 255, 255, 0));
    z-index: 3; }
  .large-header__title {
    margin: 1.25rem 0 0;
    padding: 0 1.25rem;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 900;
    z-index: 1;
    text-align: center;
    position: relative;
    opacity: 0; }
  .large-header__visual {
    position: absolute;
    width: 19.625rem;
    overflow: hidden;
    bottom: 0;
    z-index: 2; }
  .large-header__visual, .large-header__visual-fg, .large-header__visual-bg {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .large-header__visual img, .large-header__visual-fg img, .large-header__visual-bg img {
      display: inline-block;
      width: auto;
      max-width: 90%;
      height: auto;
      max-height: 80%;
      opacity: 0; }
  .large-header__visual-fg, .large-header__visual-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 20rem; }
  .large-header__visual-bg {
    z-index: 0; }
  .large-header__visual-fg {
    z-index: 2; }

@media screen and (min-width: 1024px) {
  .large-header {
    margin-top: -4.5rem;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 38.4375rem; }
    .large-header__title {
      font-size: 6.25rem; }
    .large-header__visual {
      width: 100%;
      max-width: 90rem; } }

@media screen and (min-width: 1140px) {
  .large-header {
    margin-top: -4.75rem;
    padding-top: 4.75rem;
    padding-bottom: 4.75rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .large-header__title {
      margin: 0;
      font-size: 7.5rem; }
    .large-header__visual-fg, .large-header__visual-bg {
      position: absolute;
      transform: translateX(0);
      bottom: 0;
      left: auto;
      max-width: 90rem; } }

body.page-jobs .large-header__visual {
  overflow: hidden; }
  body.page-jobs .large-header__visual img {
    margin-bottom: -1.875rem; }

body.page-cases .large-header {
  height: auto; }
  @media screen and (min-width: 1140px) {
    body.page-cases .large-header {
      height: 38.4375rem; } }

@media screen and (max-width: 1024px) {
  body.page-cases-single .large-header__title {
    margin: 0; }
  body.page-cases-single .large-header__visual,
  body.page-cases-single .large-header__visual-bg,
  body.page-cases-single .large-header {
    overflow: hidden;
    display: block;
    position: relative;
    left: auto;
    transform: none; } }

#map {
  height: 19.375rem;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
  margin: 2.5rem 0; }
  @media screen and (min-width: 768px) {
    #map {
      height: 25.625rem;
      margin: 7.5rem 0 9.25rem; } }

.section--cases {
  margin: 5rem 0 0; }
  @media screen and (min-width: 768px) {
    .section--cases {
      margin: 10rem 0 0; } }

.section--clients {
  margin: 2.5rem 0 2.5rem; }
  @media screen and (min-width: 768px) {
    .section--clients {
      margin: 5.25rem 0 10.25rem; } }

.section--services {
  background-image: linear-gradient(to bottom, #f4f6fb, rgba(244, 246, 251, 0));
  padding: 2.5rem 0 5rem; }
  @media screen and (min-width: 768px) {
    .section--services {
      padding: 3.125rem 0 7.5rem; } }

.section--articles {
  padding: 2.5rem 0 0; }
  @media screen and (min-width: 768px) {
    .section--articles {
      padding: 7.5rem 0 0; } }

.inverted .section--services {
  background: transparent !important; }

.site-footer {
  background-color: #f4f6fb;
  margin-top: 6.875rem; }
  .site-footer__inner.constrict {
    max-width: 76.25rem;
    position: relative; }
  .site-footer__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.5rem; }
    .site-footer__top .footer-widget {
      width: calc(50% - 1.25rem);
      margin-top: 2.5rem; }
      .site-footer__top .footer-widget:nth-child(1) {
        margin-top: 0;
        width: 100%; }
  .site-footer__bottom {
    display: flex;
    flex-direction: column-reverse; }

@media screen and (min-width: 720px) {
  .site-footer__top {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 5.9375rem; }
    .site-footer__top .footer-widget {
      width: 11.25rem;
      margin-left: 2.5rem;
      margin-top: 0; }
      .site-footer__top .footer-widget:nth-child(1) {
        margin-left: 0;
        width: 11.25rem;
        flex-grow: 1;
        margin-right: 2.5rem; }
  .site-footer__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; } }

@media screen and (min-width: 1140px) {
  .site-footer {
    margin-top: 11.25rem; } }

@media screen and (min-width: 720px) and (max-width: 1140px) and (orientation: portrait) {
  .site-footer {
    margin-top: 5.625rem; } }

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%; }
  .site-header__inner {
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1; }

@media screen and (min-width: 600px) {
  .site-header__inner {
    height: 4.5rem; } }

@media screen and (min-width: 768px) {
  .site-header__inner {
    height: 4.75rem; } }

@media screen and (min-width: 768px) {
  .blog-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .blog-grid__item {
      width: calc(50% - 1.25rem); } }

@media screen and (min-width: 768px) {
  .cases-grid {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap; } }

.clients-grid {
  overflow: hidden;
  overflow-x: scroll; }
  .clients-grid__inner {
    white-space: nowrap; }
  .clients-grid__item {
    width: 33.33333%;
    display: inline-block;
    align-items: center;
    margin: 1.75rem 0;
    vertical-align: middle;
    text-align: center; }
    .clients-grid__item img {
      display: inline-block;
      max-width: 80%;
      height: auto; }

@media screen and (min-width: 480px) {
  .clients-grid__item {
    width: 25%; } }

@media screen and (min-width: 600px) {
  .clients-grid {
    overflow: visible;
    overflow-x: visible; }
    .clients-grid__inner {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap; }
    .clients-grid__item {
      display: flex;
      justify-content: center;
      margin: 1.75rem 0;
      width: 20%; } }

.contact-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3.75rem; }
  .contact-grid__item {
    width: 15rem;
    margin-bottom: 2.5rem; }
    .contact-grid__item:last-child {
      margin-bottom: 0; }

/*
Atom One Dark by Daniel Gamage
Original One Dark Syntax theme from https://github.com/atom/one-dark-syntax
base:    #282c34
mono-1:  #abb2bf
mono-2:  #818896
mono-3:  #5c6370
hue-1:   #56b6c2
hue-2:   #61aeee
hue-3:   #c678dd
hue-4:   #98c379
hue-5:   #e06c75
hue-5-2: #be5046
hue-6:   #d19a66
hue-6-2: #e6c07b
*/
body {
  transition: background-color 0s ease-in-out; }
  body .logo-icon, body .logo-name, body .logo-bg {
    transition: fill .25s ease-in-out .3s; }

.inverted .logo-icon, .inverted .logo-name {
  fill: #fff; }

.inverted .logo-bg {
  fill: #000; }

.inverted .menu-icn__inner,
.inverted .menu-icn::before,
.inverted .menu-icn::after {
  background-color: #fff; }

.inverted .site-footer,
.inverted .video-text,
.inverted .section--articles {
  color: #fff; }

.inverted .site-footer {
  background-color: transparent; }

body.inverted {
  background-color: #000; }

body.show-menu .logo-icon, body.show-menu .logo-name {
  fill: #000;
  transition-delay: 0s; }

body.show-menu .logo-bg {
  fill: #fff;
  transition-delay: 0s; }

body.show-menu .menu-icn__inner,
body.show-menu .menu-icn::before,
body.show-menu .menu-icn::after {
  background-color: #000; }

#video-container {
  top: -4px !important; }

.jobs-grid__item {
  margin-bottom: 1.25rem;
  width: 100%;
  padding: 0 5px; }

@media screen and (min-width: 600px) {
  .jobs-grid {
    display: flex;
    flex-wrap: wrap; } }
  @media screen and (min-width: 600px) and (orientation: portrait) {
    .jobs-grid__item {
      width: 50%; } }

@media screen and (min-width: 720px) and (orientation: portrait) {
  .jobs-grid__item {
    width: 33.333%; } }

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .jobs-grid {
    max-width: 44.375rem;
    margin-left: auto;
    margin-right: auto; }
    .jobs-grid__item {
      width: 50%; } }

@media screen and (min-width: 1440px) {
  .jobs-grid__item {
    width: 33.333%; } }

.page-jobs .article-section__content ul, .page-jobs .article-section__content ol {
  padding: 0;
  list-style: none;
  max-width: 37.5rem;
  line-height: 2; }
  .page-jobs .article-section__content ul li, .page-jobs .article-section__content ol li {
    position: relative;
    padding-left: 1.625rem; }
    .page-jobs .article-section__content ul li:before, .page-jobs .article-section__content ol li:before {
      content: '+';
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 1.5;
      position: absolute;
      left: 0px;
      margin-top: -.03em;
      color: #009bee;
      opacity: .72; }
  @media screen and (min-width: 768px) {
    .page-jobs .article-section__content ul li:before, .page-jobs .article-section__content ol li:before {
      margin-top: .03em; } }
  @media screen and (min-width: 1024px) {
    .page-jobs .article-section__content ul ul, .page-jobs .article-section__content ul ol, .page-jobs .article-section__content ol ul, .page-jobs .article-section__content ol ol {
      font-size: 1.25rem; } }

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

.main {
  z-index: 0;
  flex-grow: 1;
  padding-top: 3.75rem; }
  @media screen and (min-width: 600px) {
    .main {
      padding-top: 4.5rem; } }
  @media screen and (min-width: 1140px) {
    .main {
      padding-top: 4.75rem; } }

.constrict {
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  .constrict--smaller {
    max-width: 71.25rem; }
  .constrict--smallest {
    max-width: 64rem; }
  .constrict--tiny {
    max-width: 49.375rem; }

@media screen and (min-width: 768px) {
  .constrict {
    padding-left: 2.5rem;
    padding-right: 2.5rem; } }

.lottie-wrapper {
  width: 100%;
  position: absolute;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%); }

.lottie-wrapper__inner {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: auto;
  overflow: hidden; }

#lottie2,
#lottie {
  width: 133.3125rem;
  height: auto;
  display: block;
  transform: translate3d(0, 0, 0);
  transform-origin: center center;
  text-align: center;
  opacity: 1;
  position: relative;
  position: absolute; }
  @media screen and (max-width: 769px) {
    #lottie2,
    #lottie {
      transform: translate3d(0, 0, 0) scale(0.8); } }
  @media screen and (max-width: 480px) {
    #lottie2,
    #lottie {
      transform: translate3d(0, 0, 0) scale(0.5); } }

body[data-lottie='3'] .lottie-wrapper__inner,
.page-home .lottie-wrapper__inner {
  top: 1.25rem;
  left: 0.9375rem;
  width: calc(100% - 0.9375rem);
  max-width: 26.5rem;
  height: 25rem; }

body[data-lottie='3'] #lottie,
.page-home #lottie {
  top: -38.125rem;
  left: -68.125rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie='3'] #lottie,
    .page-home #lottie {
      top: -38.75rem;
      left: -68.75rem; } }
  @media screen and (max-width: 480px) {
    body[data-lottie='3'] #lottie,
    .page-home #lottie {
      top: -37.8125rem;
      left: -67.8125rem; } }

body[data-lottie='6'],
.site-footer {
  position: relative; }
  body[data-lottie='6'] .lottie-wrapper,
  .site-footer .lottie-wrapper {
    top: -31.25rem;
    z-index: -1; }
  body[data-lottie='6'] .lottie-wrapper__inner,
  .site-footer .lottie-wrapper__inner {
    top: 1.25rem;
    left: auto;
    right: 0.9375rem;
    max-width: 30rem;
    width: 30rem;
    height: 30rem; }
  body[data-lottie='6'] #lottie2,
  .site-footer #lottie2 {
    top: -7.8125rem;
    left: -43.75rem;
    transform: translate3d(0, 0, 0) scale(1.17); }
    @media screen and (max-width: 770px) {
      body[data-lottie='6'] #lottie2,
      .site-footer #lottie2 {
        transform: translate3d(0, 0, 0) scale(0.8);
        top: -7.8125rem;
        left: -41.875rem; } }
    @media screen and (max-width: 480px) {
      body[data-lottie='6'] #lottie2,
      .site-footer #lottie2 {
        transform: translate3d(0, 0, 0) scale(0.5);
        top: -7.8125rem;
        left: -43.75rem; } }

body[data-lottie='2'] .lottie-wrapper__inner,
.page-blog .lottie-wrapper__inner {
  top: 0rem;
  right: 0.9375rem;
  width: calc(100% - 0.9375rem);
  max-width: 71.25rem;
  height: 30.625rem; }

body[data-lottie='2'] #lottie,
.page-blog #lottie {
  top: -33.125rem;
  left: -30rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie='2'] #lottie,
    .page-blog #lottie {
      top: -33.5625rem;
      left: -35rem; } }
  @media screen and (max-width: 480px) {
    body[data-lottie='2'] #lottie,
    .page-blog #lottie {
      top: -32.5rem;
      left: -55.3125rem; } }

body[data-lottie='5'] .lottie-wrapper__inner,
.page-contact .lottie-wrapper__inner {
  top: 4.375rem;
  right: 0.9375rem;
  width: calc(100% - 0.9375rem);
  max-width: 30rem;
  height: 26.875rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie='5'] .lottie-wrapper__inner,
    .page-contact .lottie-wrapper__inner {
      top: 3.75rem; } }

body[data-lottie='5'] #lottie,
.page-contact #lottie {
  top: -25rem;
  left: -35rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie='5'] #lottie,
    .page-contact #lottie {
      top: -28.125rem;
      left: -35.625rem; } }
  @media screen and (max-width: 480px) {
    body[data-lottie='5'] #lottie,
    .page-contact #lottie {
      top: -33.75rem;
      left: -40.9375rem; } }

body[data-lottie='1'] .lottie-wrapper,
body[data-lottie='1'] .lottie-wrapper__inner,
.page-about .lottie-wrapper,
.page-about .lottie-wrapper__inner {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none; }

body[data-lottie='1'] .lottie-wrapper__inner svg > g > g > g,
.page-about .lottie-wrapper__inner svg > g > g > g {
  opacity: .16; }
  body[data-lottie='1'] .lottie-wrapper__inner svg > g > g > g#image_55, body[data-lottie='1'] .lottie-wrapper__inner svg > g > g > g#image_47, body[data-lottie='1'] .lottie-wrapper__inner svg > g > g > g#image_39, body[data-lottie='1'] .lottie-wrapper__inner svg > g > g > g#image_27,
  .page-about .lottie-wrapper__inner svg > g > g > g#image_55,
  .page-about .lottie-wrapper__inner svg > g > g > g#image_47,
  .page-about .lottie-wrapper__inner svg > g > g > g#image_39,
  .page-about .lottie-wrapper__inner svg > g > g > g#image_27 {
    opacity: 1; }
    @media screen and (max-width: 769px) {
      body[data-lottie='1'] .lottie-wrapper__inner svg > g > g > g#image_55, body[data-lottie='1'] .lottie-wrapper__inner svg > g > g > g#image_47, body[data-lottie='1'] .lottie-wrapper__inner svg > g > g > g#image_39, body[data-lottie='1'] .lottie-wrapper__inner svg > g > g > g#image_27,
      .page-about .lottie-wrapper__inner svg > g > g > g#image_55,
      .page-about .lottie-wrapper__inner svg > g > g > g#image_47,
      .page-about .lottie-wrapper__inner svg > g > g > g#image_39,
      .page-about .lottie-wrapper__inner svg > g > g > g#image_27 {
        opacity: .16; } }

body[data-lottie='1'] #lottie,
.page-about #lottie {
  top: -12.5rem;
  right: -37.5rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie='1'] #lottie,
    .page-about #lottie {
      top: -18.75rem;
      right: -45rem; } }
  @media screen and (max-width: 480px) {
    body[data-lottie='1'] #lottie,
    .page-about #lottie {
      top: -25rem;
      right: auto;
      left: -56.25rem; } }

body[data-lottie='4'] .lottie-wrapper__inner,
.page-cases .lottie-wrapper__inner {
  top: 4.375rem;
  right: 0.9375rem;
  width: calc(100% - 0.9375rem);
  max-width: 47.5rem;
  height: 26.25rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie='4'] .lottie-wrapper__inner,
    .page-cases .lottie-wrapper__inner {
      top: 3.75rem; } }
  @media screen and (max-width: 480px) {
    body[data-lottie='4'] .lottie-wrapper__inner,
    .page-cases .lottie-wrapper__inner {
      top: 3.125rem; } }

body[data-lottie='4'] #lottie,
.page-cases #lottie {
  top: -41.25rem;
  left: -29.375rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie='4'] #lottie,
    .page-cases #lottie {
      top: -40.625rem;
      left: -26.5625rem; } }
  @media screen and (max-width: 480px) {
    body[data-lottie='4'] #lottie,
    .page-cases #lottie {
      top: -39.375rem;
      left: -49.0625rem; } }

body[data-lottie=''] .lottie-wrapper,
.page-cases-single .lottie-wrapper {
  top: 0; }

body[data-lottie=''] .lottie-wrapper__inner,
.page-cases-single .lottie-wrapper__inner {
  top: 8.125rem;
  left: 0.9375rem;
  width: calc(100% - 0.9375rem);
  max-width: 47.5rem;
  height: 29.375rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie=''] .lottie-wrapper__inner,
    .page-cases-single .lottie-wrapper__inner {
      top: 3.75rem;
      height: 21.875rem; } }

body[data-lottie=''] #lottie,
.page-cases-single #lottie {
  top: -28.125rem;
  left: -68.125rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie=''] #lottie,
    .page-cases-single #lottie {
      top: -31.25rem;
      left: -68.125rem; } }
  @media screen and (max-width: 480px) {
    body[data-lottie=''] #lottie,
    .page-cases-single #lottie {
      top: -33.75rem;
      left: -67.8125rem; } }

body[data-lottie='8'] .lottie-wrapper__inner,
.page-jobs .lottie-wrapper__inner {
  top: 0;
  right: 0.9375rem;
  width: calc(100% - 0.9375rem);
  max-width: 81.25rem;
  height: 27.5rem; }

body[data-lottie='8'] #lottie,
.page-jobs #lottie {
  top: -37.5rem;
  right: -44.6875rem; }
  @media screen and (max-width: 769px) {
    body[data-lottie='8'] #lottie,
    .page-jobs #lottie {
      right: -50rem; } }
  @media screen and (max-width: 480px) {
    body[data-lottie='8'] #lottie,
    .page-jobs #lottie {
      top: -36.875rem;
      right: -55.625rem; } }

body[data-lottie='7'] .lottie-wrapper,
body[data-lottie='7'] .lottie-wrapper__inner {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  z-index: 0; }

body[data-lottie='7'] #lottie {
  top: 50%;
  left: 50%;
  transform: translate3d(0, 0, 0) translate(-50%, -50%); }
  @media screen and (max-width: 769px) {
    body[data-lottie='7'] #lottie {
      transform-origin: center center;
      transform: translate3d(0, 0, 0) translate(-50%, -50%) scale(0.8); } }
  @media screen and (max-width: 480px) {
    body[data-lottie='7'] #lottie {
      transform: translate3d(0, 0, 0) translate(-50%, -50%) scale(0.5); } }

.large-header .lottie-wrapper {
  top: 0; }

.page-blogs-single .lottie-wrapper {
  z-index: 1; }

.services-grid__item + .services-grid__item {
  margin-top: 2.5rem; }

@media screen and (min-width: 1024px) {
  .services-grid {
    display: flex;
    justify-content: space-between; }
    .services-grid__item {
      width: calc(33.333% - 20px); }
    .services-grid__item + .services-grid__item {
      margin-top: 0; } }
