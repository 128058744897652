.case-teaser-block {
  margin: rem(20) 0 rem(40);
  position: relative;
  background-color: $snow-white;

  a {
    text-decoration: none;
  }

  &__inner {
    display: flex;
    flex-direction: column-reverse;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(to top, $white 15%, rgba($white, 0));
      z-index: 1;
    }
  }

  &__header {
    position: relative;
    z-index: 1;
    margin-top: rem(-10);
    margin-bottom: rem(10);
    width: 100%;
    padding: rem(24);
    padding-top: 0;
  }

  &__visual {
    position: relative;
    top: rem(-20);
    bottom: rem(20);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;

    img {
      max-width: rem(210);
      width: 100%;
      height: auto;
      position: relative;
      z-index: 0;
      transition: transform .2s ease-in-out;
    }
  }

  &__title {
    font-size: rem(24);
    font-weight: 900;
    color: $text-color;
    margin: 0;
    line-height: 1.1;
  }

  &__subtitle {
    color: $text-color;
    margin: 0;
    opacity: .72;
  }

  &:hover {
    .case-teaser-block__visual img {
      transform: translateY(-10px);
      transform-origin: center bottom;
    }
  }
}

.page-home .case-teaser-block__subtitle {
  display: none;
}



@include above($tablet) {
  .case-teaser-block {
    @include aspect-ratio(452, 452);
    width: calc(50% - #{rem(20)});
    height: auto;

    &__header {
      position: absolute;
      bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    &__visual {
      position: absolute;

      img {
        width: 80%;
        max-width: 100%;
      }
    }

    &__title {
      font-size: rem(40);
    }

    &__subtitle {
      margin: rem(10) 0 0;
    }
  }

  .case-teaser-block:first-child {
    width: 100%;

    &::before {
      padding-top: ((493 / 944) * 100%);
    }

    .case-teaser-block {
      &__visual {
        top: -20%;
        align-items: flex-end;

        &:before {
          height: 60%;
        }

        img {
          max-height: 100%;
          height: 100%;
          max-width: 100%;
          width: auto;
        }
      }

      &__title {
        font-size: rem(54);
      }
    }


  }
}
