.slider {
  margin-left: rem(-20);
  margin-right: rem(-20);
}

.article-section .article-section__inner .slide__image,
.slide__image {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
// .slide {
//   @include aspect-ratio(375, 146);
//   background-color: $snow-white;

//   &__inner {
//     display: flex;
//   }

//   &__content {
//     width: 60%;
//     padding: 7.3% 4% 7.3% 8.3%;
//   }

//   &__image {
//     padding: rem(20) 0 0;
//     width: 40%;
//     display: flex;
//     align-items: flex-end;
//     justify-content: center;
//   }

// }

// .article__body .slide,
// .slide {
//   &__title {
//     font-size: rem(20);
//     line-height: 1.2;
//     font-weight: 900;
//     margin: 0;
//     max-width: em(370, 32);
//   }
// }

@include above($mobile) {
  // .article__body .slide,
  // .slide {
  //   &__title {
  //     font-size: rem(32);
  //   }
  // }
}

@include above($tablet) {

  .slider {
    margin-left: rem(-40);
    margin-right: rem(-40);
  }
}

@include above($desktop-sm) {
  .slider {
    margin-left: rem(50);
    margin-right: rem(50);
  }

  // .slide {
  //   &__content {
  //     padding: rem(70) rem(40) rem(70) rem(80);
  //   }
  // }
  // .article__body .slide,
  // .slide {
  //   &__title {
  //     font-size: rem(54);
  //   }
  // }
}
