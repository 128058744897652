.related-cases {

}

.related-case {
  width: 100%;
  margin: rem(40) 0;

  a {
    color: inherit;
    text-decoration: none;
  }

  &__inner {
    display: flex;
    align-items: stretch;
    height: 100%;
  }

  &__label {
    display: none;
  }

  &__item {
    width: 100%;
    background-color: $snow-white;
    padding: rem(26);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(30);

    img {
      max-width: rem(280);
      width: 80%;
      height: auto;
    }
  }

  &__title {
    font-size: rem(32);
    margin: 0;
    line-height: 1.1;
    font-weight: 900;
  }

}


.related-case--next {
  .related-case__inner {
    flex-direction: row-reverse;
  }

  .related-case__label__inner{
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
  }
}


.related-case--prev {
  .related-case__label__inner{
    transform: rotate(90deg) translateY(-100%);
    transform-origin: top left;
  }
}



@include above($tablet-md) {
  .related-cases {
    max-width: rem(720);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(40);

    &.constrict {
      padding: 0;
    }
  }

  .related-case {
    width: rem(340);
    height: rem(414);

    &__label {
      width: rem(60);
      height: 100%;
      position: relative;
      text-transform: uppercase;
      font-weight: 700;
      font-size: rem(16);
      display: block;
    }

    &__label__inner {
      height: rem(60);
      width: rem(414);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__item {
      width: rem(280);
    }

    &__image {
      height: rem(280);
      margin-top: 0;
    }
  }
}


@include above($desktop-sm) {

  .related-cases {
    max-width: rem(840);
    margin-top: rem(90);
  }

  .related-case {
    width: rem(400);
    height: rem(500);

    &__item {
      width: rem(340);
    }

    &__label {
      font-size: rem(20);
    }

    &__label__inner {
      width: rem(500);
    }

    &__image {
      height: rem(340);
    }

    &__title {
      font-size: rem(40);
    }
  }
}
