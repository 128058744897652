.clients-title {
  $fs: 40;
  font-size: rem(24);
  line-height: 1;
  font-weight: 900;
  margin: 0 0 em(50, $fs);
  text-align: center;
}


@include above($tablet) {
  .clients-title {
    font-size: rem(40);
  }

}
