.footer-widget {

  a {
    color: inherit;
  }

  &__text,
  &__nav {
    opacity: .72;
    margin: 0;
  }

  &__text,
  &__nav {
    p {
      margin: 0;
    }
  }

  &__title {
    margin: .5em 0;
    font-weight: 900;
    font-size: rem(24);
    line-height: lh(39,32);
  }
}


@include above($tablet) {
  .footer-widget {
    &__title {
      font-size: rem(32);
    }
  }
}
