body {
  transition: background-color 0s ease-in-out;

  .logo-icon, .logo-name, .logo-bg  {
    transition: fill .25s ease-in-out .3s;
  }
}

.inverted {

  .logo-icon, .logo-name  {
    fill: $white;
  }

  .logo-bg {
    fill: $black;
  }

  .menu-icn__inner,
  .menu-icn::before,
  .menu-icn::after {
    background-color: $white;
  }

  .site-footer,
  .video-text,
  .section--articles {
    color: $white;
  }

  .site-footer {
    background-color: transparent;
  }
}

body.inverted {
  background-color: $black;
}

body {

  &.show-menu {
    .logo-icon, .logo-name {
      fill: $black;
      transition-delay: 0s;
    }

    .logo-bg {
      fill: $white;
      transition-delay: 0s;
    }

    .menu-icn__inner,
    .menu-icn::before,
    .menu-icn::after {
      background-color: $black;
    }
  }
}

#video-container {
  top: -4px !important;
}