.article-section.article-section--double-text {

    .article-section__inner {
        display: flex;
        justify-content: space-between;

        @include below($tablet){
            flex-wrap: wrap;
        }

        &.article-doubletext-reverse {

            @include below($tablet){
                .article-section__content:first-child {
                    order: 2;
                }
            }
        }

        .article-section__content {
    
            width: calc(50% - 10px);

            &:first-child {
                padding-right: 10px;
            }
    
            h1, h2 { 
                margin-top: rem(16);
            }
    
            @include below($tablet){
                width: 100%;
                padding: 0;
            }
        }
    }


}