.logo {
  display: block;

  svg {
    display: block;
    width: 100%;
    height: auto;

    path {
      fill: #000000;
    }

    path.logo-name {
      display: none;
    }
  }
}


.site-header .logo {
  width: rem(32);
  height: rem(32);
  overflow: hidden;

  svg {
    display: block;
    width: auto;
    height: 100%;
  }
}


.site-footer .logo {
  width: rem(72);
  height: rem(72);
  overflow: hidden;
  transform: translateY(-50%);

  svg {
    display: block;
    width: auto;
    height: 100%;
  }
}


@include above($tablet-xs) {
  .logo {
    display: block;

    svg {
      path.logo-name {
        display: block;
      }
    }
  }

  .site-header .logo {
    width: auto;
    height: rem(40);
  }
}


@include above($tablet) {
  .site-header .logo {
    height: rem(44);
  }
}
