@include above($tablet) {
  .blog-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      width: calc(50% - #{rem(20)});
    }
  }

}
