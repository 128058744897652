
.main-nav__item a {
  position: relative;
  padding-left: rem(12);
  z-index: 0;

  .dot {
    width: rem(9);
    height: rem(9);
    display: inline-block;
    background-color: $dutch-orange;
    position: absolute;
    bottom: .25em;
    left: 0;
    transition: width .25s ease-in-out;
    z-index: -1;
  }


  &:hover {
    .dot {
      width: 100%;
    }
  }
}



@include above($tablet) {
  .main-nav__item a  {
    padding-left: rem(17);

    .dot {
      width: rem(11);
      height: rem(11);
    }
  }
}


.main-nav__item a.active .dot {
  width: 100%;
}
