.article-section--benefits {

  .article-section__inner {
    display: flex;
    flex-direction: column-reverse;
    max-width: rem($full-width-max);
    margin-left: auto;
    margin-right: auto;
  }


  .article-section__image {
    margin-top: rem(40);
  }

  .article-section__content {
    padding-left: rem(20);
    padding-right: rem(20);

    h2 {
      margin-top: 0;
    }
  }

  ul, ol {
    padding: 0;
    list-style: none;
    max-width: rem(600);
    line-height: lh(48, 24);

    li {
      position: relative;
      padding-left: rem(26);

      &:before {
        content: '+';
        font-weight: 900;
        font-size: rem(24);
        line-height: lh(48, 32);
        position: absolute;
        left: 0px;
        margin-top: -.03em;
        color: $sky-blue;
        opacity: .72;
      }
    }
  }
}


@include above($tablet-xs) {
  .article-section--benefits {
    .article-section__inner {
      flex-direction: row;
    }

    .article-section__image,
    .article-section__content {
      width: 50%;
    }

    .article-section__image {
      padding-right: rem(20);
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }
}


@include above($tablet) {
  .article-section--benefits {


    .article-section__content {
      padding-left: rem(20);
      padding-right: rem(20);
    }

    .article-section__image {
      padding-right: rem(20);
      margin-top: 0;
    }


    ul, ol {
      li {
        &:before {
          margin-top: .03em;
        }
      }
    }
  }
}

@include above($desktop-sm) {
  .article-section--benefits {
    ul, ol {
      font-size: rem(20);
    }
  }
}

@include above($desktop) {
  .article-section--benefits {


    .article-section__content {
      padding-left: rem(45);
      padding-right: rem(30);
    }

    .article-section__image {
      padding-right: rem(45);
    }


    ul, ol {
      font-size: rem(24);
      line-height: lh(48, 24);

      li {
        padding-left: rem(35);

        &:before {
          font-size: rem(32);
        }
      }
    }
  }
}
