// ==========================================================================
// Variables
// ==========================================================================

$root-box-sizing: border-box;

$mobile: 480px;
$tablet-xs: 600px;
$tablet-md: 720px;
$tablet: 768px;
$desktop-sm: 1024px;
$desktop: 1140px;
$desktop-md: 1250px;
$desktop-hd: 1440px;
$hd: 1650px;
$desktop-xxl: 2000px;

$full-width-max: $hd;

$base-margin: 20px;


// Colors
$black: #000;
$white: #fff;
$red: #E25050;
$green: #6DE250;
$cloud-grey: #414f64;
$navy-blue: #19277d;
$snow-white: #f4f6fb;
$dutch-orange: #ef5a22;
$sky-blue: #009bee;
$electric-purple: #912fef;
$cornflower: #635aff;

$text-color: $cloud-grey;
$error-color: $red;
$success-color: $green;

$header-height-small: 60;
$header-height-medium: 72;
$header-height-large: 76;

// Paths
$base-path: '../';
$base-font-path: $base-path + 'fonts/';
$base-img-path:  $base-path + 'img/';




// Fonts
$ff-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$ff-primary: 'Lato', sans-serif;
$ff-secondary: 'Poppins', sans-serif;
$ff-mono: Courier, monospace;

// Transitions
$base-transition: 'all .3s ease';

// Variables for functions
$vw-base: $desktop-hd;
$default-font-size: 16;
