.main-nav {
  transition: transform .25s ease-in-out;
  transition-delay: .25s;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  top: 0;
  background-color: $white;
  padding-top: rem(90);
  transform: translateY(-100%);
  display: none;

  &__list {
    text-align: left;
    padding: rem(30) rem(40) rem(80);
    position: relative;
    opacity: 0;

    transition: opacity .25s ease-in-out;
    transition-delay: 0s;
  }

  &__item {
    $fs: 36;
    font-size: rem($fs);
    line-height: lh(40, 36);
    font-weight: bold;
    font-family: $ff-secondary;
    position: relative;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__item + &__item {
    margin-top: rem(24);
  }
}

.show-menu .main-nav {
  transform: translateY(0);
  transition-delay: 0s;

  &__list {
    opacity: 1;
    transition-delay: .25s;
  }

}



@include above($tablet) {
  .main-nav {
    padding-top: rem(76);

    &__list {
      padding: rem(30) rem(40) rem(56);
      text-align: center;
    }

    &__item {
      font-size: rem(54);
    }

    &__item + &__item {
      margin-top: rem(36);
    }
  }
}


@include between($mobile, 1023px) {

  @media (orientation: landscape) {
    .main-nav {

      &__list {
        text-align: left;
        columns: 2;
        padding: 0 rem(40) rem(56);
        margin-top: rem(24)
      }

      &__item {
        font-size: rem(36);
      }

      &__item + &__item {
        margin-top: rem(24);
      }
    }
  }

}
