.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, .08);
  z-index: -999;
  opacity: 0;
  transition-property: z-index, opacity;
  transition-duration: 0s, .3s;
  transition-delay: .8s, .3s;

 

  @supports ((-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px))) {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
 
  

  .show-menu & {
    z-index: 98;
    opacity: 1;
    transition-delay: 0s, .1s;
  }
}


@supports not ((-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px))) {
  .main {
    transition: filter .3s ease-in-out;
  }

  .show-menu .main {
    filter: blur(8px);
    @-moz-document url-prefix() {
     filter: none ;
    }
  }
}
