.page-header--image {
  margin-top: rem(-$header-height-small);
  //color: $white;
  position: relative;

  .page-header__bg {
    position: relative;
    z-index: 0;
    height: rem(415);

    img {
      width: 100%;
      height: 100%;
      max-height: 616px;
      object-fit: cover;
      object-position: center;
    }
  }

  .page-header__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: rem(120);
    z-index: 2;
  }

  .page-header__title {
    margin-top: 0;
  }
}

@include above($mobile) {
  .page-header--image {
    .page-header__bg {
      height: auto;
    }

    .page-header__inner {
      top: 30%;
    }
  }
}

@include above($tablet-xs) {
  .page-header--image {
    margin-top: rem(-$header-height-medium);

    .page-header__inner {
      top: 30%;
    }
  }
}

@include above($tablet) {
  .page-header--image {
    margin-top: rem(-$header-height-medium);
  }
}



@include above($desktop) {
  .page-header--image {
    margin-top: rem(-$header-height-large);
  }
}


.inverted .page-header--image {
  color: $white;
}