.clients-grid {
  overflow: hidden;
  overflow-x: scroll;

  &__inner {
    white-space: nowrap;
  }

  &__item {
    width: (100% / 3);
    display: inline-block;
    align-items: center;
    margin: rem(28) 0;
    vertical-align: middle;
    text-align: center;

    img {
      display: inline-block;
      max-width: 80%;
      height: auto;
    }
  }

}


@include above($mobile) {
  .clients-grid {

    &__item {
      width: (100% / 4);
    }

  }
}


@include above($tablet-xs) {
  .clients-grid {
    overflow: visible;
    overflow-x: visible;

    &__inner {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
    }

    &__item {
      display: flex;
      justify-content: center;
      margin: rem(28) 0;
      width: (100% / 5);
    }
  }
}
