.marked {
  position: relative;

  u {
    background-color: rgba($sky-blue, .32);
    position: absolute;
    height: rem(9);
    bottom: .25em;
    left: -.1em;
    right: calc(100% + .1em);
    z-index: -1;
    animation-name: UNDERLINE;
    animation-duration: .25s;
    animation-delay: .25s;
    animation-timing-function: ease-in-out;
    animation-fill-mode:forwards;
  }
}

// .page-header__title .marked u {
//   background-color: rgba($sky-blue, .32);
// }

@keyframes UNDERLINE {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
  }
}


@include above($tablet-xs) {
  .marked {
    u {
      height: rem(12);
    }
  }
}


@include above($tablet) {
  .marked {
    u {
      height: rem(16);
    }
  }
}
