// .article-section--intro {

//   .article-section__inner {
//     display: flex;
//   }

//   h2,
//   .article-section__content {
//     width: 50%;
//   }

//   h2 {
//     padding-right: em(20);
//     margin: 0;
//     max-width: rem(360);
//     margin-left: auto;
//   }

//   .article-section__content {
//     padding-left: rem(20);
//   }
// }

// .article__body .article-section--intro h2 {
//   margin: 0;
//   margin-left: auto;
// }

@include above($tablet) {
  .article-section--intro {

    .article-section__inner {
      display: flex;
    }


    h2 {
      width: 30%;
      padding-right: em(20);
      margin: 0;
      max-width: rem(360);
      margin-left: auto;
    }

    .article-section__content {
      width: 70%;
      padding-left: rem(20);

      p {
        margin-top: 0;
      }
    }
  }

  .article__body .article-section--intro h2 {
    margin: 0;
    margin-left: auto;
  }
}


@include above($desktop-sm) {
  .article-section--intro {
    h2,
    .article-section__content {
      width: 50%;
    }
  }
}
