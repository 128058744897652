.article {
  &__header-img {
    // max-width: rem($full-width-max);
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(-$header-height-small);
  }

  &__inner {
    margin-top: rem(20);
  }
}

.article.has-sidebar {
  .article__body .constrict {
    padding: 0;
  }
}

@include above($tablet-xs) {
  .article {
    &__header-img {
      margin-top: rem(-$header-height-medium);
    }
  }
}

@include above($tablet + 1) {
  .article {

    &__inner {
      margin-top: rem(80);
      padding-left: rem(122);
      padding-right: rem(122);
      position: relative;
    }

    &__header-img {
      margin-top: rem(-$header-height-large);
    }

    &__sidebar {
      // width: rem(112);
      position: absolute;
      left: rem(40);
    }

    &__sidebar + &__content {
      // width: calc(100% - #{rem(112)});
      max-width: rem(712);
    }
  }

  .article.has-sidebar {
    .article__inner {
      display: flex;
    }
  }
}

.article-section {
  .article-section__inner {
    img {
      width: 100%;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }

    img.alignnone {
      width: auto;
      max-width: 100%;
      height: auto;
      margin: rem(40) auto;
      display: block;
    }

    img.aligncenter {
      width: 100%;
      height: auto;
      display: block;
      margin: rem(40) auto;
    }

    img.size-medium {
      width: 100% !important;
    }

    a {
      img {
        width: auto;
        max-width: 100%;
      }
    }
  }
}


@include above($desktop + 1) {
  .article {

    &__inner {
      padding-left: rem(152);
      padding-right: rem(152);
      position: relative;
    }
  }
}

@include above($tablet + 1) {
  .article-section {
    .article-section__inner {

      img.size-medium {
        width: 50% !important;
        display: block;
      }
    }
  }
}
